<script setup>
import Breadcrumb from '@/components/templates/breadcrumb/component/Breadcrumb.vue'
import { useBreadcrumb } from '@/components/templates/breadcrumb/useBreadcrumb'

const { getItems } = useBreadcrumb()
</script>
<template>
  <div class="page-content-inner" style="margin-bottom: 4px">
    <Breadcrumb with-icons separator="arrow" :items="getItems" />
  </div>
</template>
