import { directives } from '@docosoft/docosoft-ui-components'
import addTooltipDirective from './tooltip/'

/**
 * A function to add directives to a Vue application.
 * @param {import('vue').App} app - The Vue application instance.
 */
const addDirectives = (app) => {
  addTooltipDirective(app)
  directives.addClickOutsideDrivective(app)
}

export default addDirectives
