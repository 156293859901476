<script setup>
import { ref, watch } from 'vue'
import { RouteItems } from '@/router/routes'
import { useRouter } from 'vue-router'
import NotificationItem from '@/components/components/notifications/NotificationItem.vue'

const router = useRouter()
const props = defineProps({
  username: String,
  isDarkMode: Boolean,
  isDisabled: Boolean,
  data: Object,
})

const data = ref(props.data)
watch(
  () => props.data,
  (next) => {
    data.value = next
  }
)
const removeNotification = (notificationId) => {
  data.value.notifications = data.value.notifications.filter(
    (item) => item.id !== notificationId
  )

  data.value.count = data.value.notifications.length
}
</script>
<template>
  <Dropdown right spaced role="notifications-dropdown">
    <template #button="{ toggle }">
      <div class="icon">
        <IconButton
          id="notifications-dropdown"
          height="2rem"
          :color="isDarkMode ? 'var(--orange)' : 'var(--purple)'"
          :icon="isDisabled ? 'mdi:bell-off-outline' : RouteItems.Notifications.icon"
          @onClick="
            () => {
              if (props.data.count === 0) {
                router.push({ name: RouteItems.Notifications.name })
                return
              }
              toggle()
            }
          "
          :name="RouteItems.Notifications.name"
          :title="RouteItems.Notifications.title"
          :tooltipText="
            isDisabled
              ? $t('components.notificationsUnavailable')
              : $t('common.notifications')
          "
          tooltipPosition="bottom"
        />
        <a
          v-if="!isDisabled && props.data.count > 0"
          @click="
            () => {
              if (props.data.count === 0) {
                router.push({ name: RouteItems.Notifications.name })
                return
              }
              toggle()
            }
          "
          class="badge-count"
          >{{ props.data.count }}</a
        >
      </div>
    </template>

    <template #content="{ toggle }">
      <div :class="['dropdown-head']">
        <h5>{{ $t('common.notifications') }}</h5>
      </div>

      <div v-if="data.count > 0" class="dropdown-content">
        <div v-for="item in data.notifications" :key="item.id" class="dropdown-items">
          <NotificationItem
            :item="item"
            @toggle="
              () => {
                removeNotification(item.id)
                toggle()
              }
            "
          />
          <hr class="dropdown-divider" />
        </div>
      </div>
      <div v-else class="dropdown-empty">
        <label>{{ $t('components.noPendingNotifications') }}</label>
      </div>
      <div class="dropdown-footer">
        <Button
          class="all-button"
          icon="octicon:list-unordered-16"
          color="info"
          role="menuitem"
          :outlined="true"
          @click="
            () => {
              toggle()
              router.push({ name: RouteItems.Notifications.name })
            }
          "
          >{{ $t('components.seeAll') }}</Button
        >
      </div>
    </template>
  </Dropdown>
</template>
<style lang="scss">
.dropdown-head {
  text-align: center;
  background: #fafafa;

  h5 {
    padding: 5px !important;
  }
}

.dropdown-items {
  padding-left: 10px;
  padding-left: 5px;
  padding-top: 5px;
  padding-right: 5px;
}

.dropdown-items:last-child {
  .dropdown-divider {
    display: none !important;
  }
}

.dropdown-content {
  padding-top: 0 !important;
}

.dropdown-divider {
  margin: 0 !important;
  height: 0.5px !important;
}

.dropdown-empty {
  max-width: 35rem;
  min-width: 35rem;
  padding: 1rem;
  font-weight: 600;
  text-align: center;
}

.badge-count {
  background-color: red;
  font-size: xx-small;
  position: absolute;
  padding: 2px;
  top: -5px;
  left: 10px;
  border-radius: 50%;
  color: white !important;
  font-weight: 700;
  display: inline-block;
  min-height: 13px;
  min-width: 13px;
  text-align: center;
  vertical-align: middle;
}

.dropdown-footer {
  padding-top: 10px;
  padding-bottom: 5px;
  text-align: center;

  .icon svg {
    vertical-align: middle;
  }

  .all-button {
    width: 100px;

    .icon {
      margin-bottom: 3px;
    }
  }
}

.is-dark {
  div.dropdown {
    .dropdown-head {
      background-color: var(--dark-background-color-layer-2);

      h5 {
        color: var(--bone-white);
      }
    }

    .dropdown-content {
      color: var(--bone-white);
    }
  }
}
</style>
