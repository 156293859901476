// @ts-check
import { toRaw } from 'vue'
import { defineStore, acceptHMRUpdate } from 'pinia'
import { Stores } from '@/stores/Stores'
import { useLogger } from '@/services/useLogger'

export const useApplicationStoreNotPersistant = defineStore(
  Stores.ApplicationStoreNotPersistant,
  {
    state: () => ({
      logger: useLogger(),
      applicationReady: false,
      requests: {
        unauthorized: false,
        isRunning: false,
        isError: false,
        /** @type {string[]} */
        list: [],
        showSpinner: true,
      },
    }),
    getters: {
      isApplicationReady: (state) => state.applicationReady,
      isRequestRunning: (state) => state.requests.isRunning,
      shouldShowRequestSpinner: (state) => state.requests.showSpinner,
    },
    actions: {
      setApplicationReady(/** @type {string} */ requestId) {
        this.logger.log('[ApplicationStoreNotPersistant: setApplicationReady]', {
          requestId,
        })

        this.applicationReady = true
        this.requestFinished(requestId)
      },
      setLoadingApplication() {
        this.applicationReady = false
        const requestId = this.setIsRequestRunning()

        this.logger.log('[ApplicationStoreNotPersistant: setLoadingApplication]', {
          requestId,
        })

        return requestId
      },
      setIsRequestRunning(/** @type {boolean} */ showSpinner = true) {
        const start = Date.now()
        /** @type {string} */
        const requestId = (Math.random() + 1).toString(36).substring(2)

        this.logger.log('[ApplicationStoreNotPersistant: setIsRequestRunning]', {
          requestId,
          showSpinner,
          start,
        })

        this.requests.showSpinner = showSpinner
        this.requests.isRunning = true
        this.requests.list.push(requestId)
        return requestId
      },
      requestFinished(/** @type {string} */ requestId) {
        const end = Date.now()

        this.logger.log('[ApplicationStoreNotPersistant: requestFinished]', {
          requestId,
          end,
          requests: toRaw(this.requests.list),
        })

        if (!requestId) {
          this.logger.warn(
            '[ApplicationStoreNotPersistant: requestFinished} RequestId not valid!',
            requestId
          )
        }

        const finishedRequestIndex = this.requests.list.indexOf(requestId)

        if (finishedRequestIndex < 0) {
          this.logger.warn(
            '[ApplicationStoreNotPersistant: requestFinished] RequestId not found!',
            requestId
          )

          return
        }

        this.requests.list = this.requests.list
          .slice(0, finishedRequestIndex)
          .concat(this.requests.list.slice(finishedRequestIndex + 1))

        // Wrapped in setTimeout with 50 ms delay to reduce overlay flickering issues
        // caused by calling multiple requests in short time without wrapping them in requestsWrapper
        const self = this
        setTimeout(() => {
          if (self.requests.list.length) {
            this.logger.log(
              '[ApplicationStoreNotPersistant: requestFinished] Some requests still running',
              this.requests.list
            )
            return
          }

          self.requests.isRunning = false
          // resets showSpinner flag to the default value
          self.requests.showSpinner = true
        }, 50)

        return end
      },
    },
  }
)

// @ts-ignore
if (import.meta.hot) {
  // @ts-ignore
  import.meta.hot.accept(
    // @ts-ignore
    acceptHMRUpdate(useApplicationStoreNotPersistant, import.meta.hot)
  )
}
