import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import moment from 'moment'
dayjs.extend(utc)
dayjs.extend(customParseFormat)
dayjs.extend(relativeTime)

export const dateTimeIsoFormat = (value) => {
  if (value) {
    return dayjs(value).format()
  }

  return ''
}

export const dateTimeUtcIsoFormat = (value) => {
  if (value) {
    return dayjs(value).utc().format()
  }

  return ''
}

export const dateTimeFormat = (value) => {
  if (value) {
    return dayjs(value).format('DD/MM/YYYY HH:mm:ss')
  }

  return ''
}

export const dateTimeLocalFormat = (value) => {
  if (value) {
    return dayjs.utc(value).local().format('DD/MM/YYYY HH:mm:ss')
  }

  return ''
}

export const dateFormat = (value) => {
  if (value) {
    return dayjs(value).format('DD/MM/YYYY')
  }

  return ''
}

export const dateLocalFormat = (value) => {
  if (value) {
    return dayjs.utc(value).local().format('DD/MM/YYYY')
  }

  return ''
}

export const dateFormatToDateObj = (value) => {
  if (value) {
    return dayjs(value, ['DD/MM/YYYY']).toDate()
  }

  return ''
}

export const dateStringLocalFormat = (value) => {
  if (value) {
    return dayjs.utc(value).local().format('YYYY-MM-DD')
  }

  return ''
}

export const dateStringFormat = (value) => {
  if (value) {
    return dayjs(value).format('YYYY-MM-DD')
  }

  return ''
}

export const utcIsoStringToLocalDateObj = (value) => {
  return dayjs.utc(value).local().toDate()
}

export const getDayjsDate = (value) => {
  if (value) {
    return dayjs(value)
  }

  return dayjs()
}

export const dateStartOfDay = (value) => {
  return dayjs(value).startOf('day')
}

export const dateEndOfDay = (value) => {
  return dayjs(value).endOf('day')
}

export const fromNow = (date) => {
  if (!date) {
    return date
  }

  return dayjs(date).fromNow()
}

export const isValidDate = (date) => {
  return (
    dayjs(
      date,
      [
        'YYYY/MM/DD HH:mm:ss',
        'DD/MM/YYYY HH:mm:ss',
        'YYYY-MM-DDTHH:mm:ss',
        'YYYY-MM-DD HH:mm:ss',
        'YYYY-MM-DDTHH:mm:ss.SSS',
        'YYYY-MM-DD HH:mm:ss.SSS',
      ],
      true
    ).isValid() ||
    moment(
      date,
      ['YYYY-MM-DDTHH:mm:ss.SSSSSSS[Z]', 'YYYY-MM-DDTHH:mm:ss.SSSSSSS'],
      true
    ).isValid()
  )
}

export const getStartOfCurrYearDate = () => {
  return new Date(new Date().getFullYear(), 0, 1)
}
