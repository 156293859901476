export const getUserLocale = () => {
  const localeWithRegion =
    window.navigator.languages[0] ||
    window.navigator.userLanguage ||
    window.navigator.language
  const locale = localeWithRegion.split('-')[0]

  return { locale, localeWithRegion }
}

export const openRouteInNewTab = (route) => {
  const absoluteURL = new URL(
    route.href,
    window.location.origin + window.location.pathname
  ).href

  const tab1 = window.open(absoluteURL, '_blank')
  tab1.name = window.name
}
