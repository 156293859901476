// @ts-check
import { defineRule } from 'vee-validate'
import { i18n } from '@/plugins/useVueI18n'

defineRule('required', (value) => {
  if (isEmpty(value)) {
    const { t } = i18n.global
    return t('forms.required')
  }

  return true
})

const isEmpty = (value) => {
  return value === null || value === undefined || value === ''
}

defineRule('maxNumber', (value, max) => {
  if (value && value > max) {
    const { t } = i18n.global
    return t('forms.number.max', { max })
  }

  return true
})

defineRule('minNumber', (value, min) => {
  if (value && value < min) {
    const { t } = i18n.global
    return t('forms.number.min', { min })
  }
  return true
})

defineRule('noGreatherThan', (value, targets = [], ctx) => {
  const [target, labelSource, labelTarget] = targets
  return noGreatherThan(value, [target, labelSource, labelTarget], ctx)
})

const noGreatherThan = (value, [target, labelSource, labelTarget], ctx) => {
  const targetValue = ctx.form[target]

  if (isSet(targetValue)) {
    const { t } = i18n.global

    return value <= targetValue
      ? true
      : t('forms.noGreaterThen', { source: labelSource, target: labelTarget })
  }

  return true
}

const isSet = (value) => {
  return value !== undefined && value !== null && value !== '' && value !== false
}

defineRule('integer', (value) => {
  const { t } = i18n.global

  return Number.isInteger(value) ? true : t('forms.number.integer')
})
