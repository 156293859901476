import { AbilityBuilder, createMongoAbility, subject } from '@casl/ability'
import {
  AllReportAccess,
  ClaimSearchAccess,
  ClaimsReportAccess,
  TaskReportAccess,
  PaymentReportAccess,
  FinancialReportAccess,
  AccessAuditReportAccess,
  AdminAuditReportAccess,
  ClaimAuditReportAccess,
  EmailAuditReportAccess,
  EmailTaskDetailsReportAccess,
  AllAdminFullAccess,
  AllAdminReadAccess,
  OptionsAdminReadAccess,
  OptionsAdminFullAccess,
  MasterDataAdminFullAccess,
  MasterDataAdminReadAccess,
  TaskRagAdminFullAccess,
  TaskRagAdminReadAccess,
  SubclaimAdminReadAccess,
  SubclaimAdminFullAccess,
  AlertsAdminReadAccess,
  AlertsAdminFullAccess,
  PaymentsAdminReadAccess,
  PaymentsAdminFullAccess,
  ClassOfBusinessAdminReadAccess,
  ClassOfBusinessAdminFullAccess,
  ClassOfBusinessGroupAdminReadAccess,
  ClassOfBusinessGroupAdminFullAccess,
  HolidayAdminReadAccess,
  HolidayAdminFullAccess,
  PaymentEmailAdminFullAccess,
  ConfigAdminReadAccess,
  ConfigAdminFullAccess,
  ApprovalLimitsAdminReadAccess,
  ApprovalLimitsAdminFullAccess,
  UsersAdminReadAccess,
  UsersAdminFullAccess,
  RolesAdminReadAccess,
  RolesAdminFullAccess,
  OrgAdminFullAccess,
  OrgAdminReadAccess,
  WorkflowAdminFullAccess,
  WorkflowAdminReadAccess,
  TriageAdminFullAccess,
  TriageAdminReadAccess,
  EmailTemplatesAdminReadAccess,
  EmailTemplatesAdminFullAccess,
  ParticipantsAdminReadAccess,
  ParticipantsAdminFullAccess,
  DashboardAllToggleAccess,
  DashboardCustomToggleAccess,
  claimsFullAccessSufix,
  claimsReadAccessSufix,
  OutstandingEmailInboxAccess,
  ManagerEmailInboxAccess,
  EmailInboxAccess,
} from '@/constants/permissions'

const { build } = new AbilityBuilder(createMongoAbility)
const ability = build()

const subjectType = {
  claim: 'claim',
}
const action = {
  read: 'read',
  modify: 'modify',
}
const allClaimsCob = 'All'

/**
 * Checks if provided claim specific permission is a read access permission.
 * @param {string} permission
 * @returns {boolean}
 */
const hasClaimReadAccess = (permission) => permission.endsWith(claimsReadAccessSufix)

/**
 * Checks if provided claim specific permission is a full access permission.
 * @param {string} permission
 * @returns {boolean}
 */
const hasClaimFullAccess = (permission) => permission.endsWith(claimsFullAccessSufix)

/**
 * Checks if provided permission is claim specific.
 * @param {string} permission
 * @returns {boolean}
 */
const isClaimPermission = (permission) =>
  hasClaimReadAccess(permission) || hasClaimFullAccess(permission)

/**
 * Retrievs action type from provided permission.
 * @param {string} permission
 * @returns {string | undefined} action type
 */
const getClaimAction = (permission) => {
  if (hasClaimReadAccess(permission)) return action.read
  if (hasClaimFullAccess(permission)) return action.modify
  return
}

/**
 * Retrievs claims class of business from provided permission.
 * @param {string} permission
 * @returns {string} claims class of business
 */
const getClaimCOB = (permission) => {
  if (hasClaimReadAccess(permission)) return permission.split(claimsReadAccessSufix)[0]

  if (hasClaimFullAccess(permission)) return permission.split(claimsFullAccessSufix)[0]

  return ''
}

/**
 * Returns claim class of business specific abilities.
 * @param {Array<string>} permissions List of all permissions.
 * @returns {Array<{action: string, subject: string, conditions: {cob: {$in: Array<string>}}}>}
 */
const getClaimAbilities = (permissions) => {
  const claimAbilities = permissions
    .map((permission) => {
      return {
        action: getClaimAction(permission),
        cob: getClaimCOB(permission),
      }
    })
    .reduce((grouped, current) => {
      const matchingIndex = grouped.findIndex((x) => x.action === current.action)

      if (matchingIndex >= 0) {
        grouped[matchingIndex].cob.push(current.cob)
        return grouped
      } else {
        return [...grouped, { action: current.action, cob: [current.cob] }]
      }
    }, [])
    .map((permission) => {
      return {
        action: permission.action,
        subject: subjectType.claim,
        conditions: { cob: { $in: [...permission.cob] } },
      }
    })

  return claimAbilities
}

/**
 * Returns non claim specific permissions.
 * @param {Array<string>} permissions List of all permissions.
 * @returns {Array<string>} permissions List of non-claim specific permissions.
 */
const getOtherAbilities = (permissions) => {
  const otherAbilities = permissions.map((permission) => {
    return { action: permission }
  })

  return otherAbilities
}

/**
 * Updates rules of Ability instance with provided list of permissions.
 * @param {Array<string>} permissions List of permissions to add.
 */
const updateAbility = (permissions) => {
  if (!Array.isArray(permissions)) return

  const claimsPermissions = permissions.filter((x) => isClaimPermission(x))
  const otherPermissions = permissions.filter((x) => !isClaimPermission(x))

  const claimAbilities = getClaimAbilities(claimsPermissions)
  const otherAbilities = getOtherAbilities(otherPermissions)

  const abilities = [...claimAbilities, ...otherAbilities]

  ability.update(abilities)
}

/* Default action ability check */

/**
 * Checks if user has specific permission.
 * @param {string} action Required permission
 * @returns {boolean} Returns `true` if user has permission, else `false`.
 */
const can = (action) => {
  return ability.can(action)
}

/* Claim cob access checks */

/**
 * Checks if user has permission to perform provided action type on a claim with provided class of business.
 * (explicitly or by having permission to perform provided action type to all classes of business).
 * @param root0
 * @param root0.action
 * @param root0.cob
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const checkClaimAccess = ({ action, cob }) => {
  const cobClaim = subject(subjectType.claim, { cob })
  const allCobClaim = subject(subjectType.claim, { cob: allClaimsCob })

  return ability.can(action, allCobClaim) || ability.can(action, cobClaim)
}

/**
 * Checks if user has full access permission to claim with provided class of business.
 * (explicitly or by having full access permission to all classes of business).
 * @param {{cob: string}} claim class of business
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canModifyClaim = ({ cob }) => {
  return checkClaimAccess({ action: action.modify, cob })
}

/**
 * Checks if user has read access permission to claim with provided class of business.
 * (explicitly or by having read access permission to all classes of business).
 * @param {{cob: string}} claim class of business
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canReadClaim = ({ cob }) => {
  return checkClaimAccess({ action: action.read, cob })
}

/* Admin full access checks */

/**
 * Checks if user has full access permission to all administration screens.
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canModifyAdmin = () => {
  return can(AllAdminFullAccess)
}

/**
 * Checks if user has full access permission to options administration screen
 * (explicitly or by having full access permission to all administartion screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canModifyOptionsAdmin = () => {
  return canModifyAdmin() || can(OptionsAdminFullAccess)
}

/**
 * Checks if user has full access permission to subclaim administration screen
 * (explicitly or by having full access permission to all administartion screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canModifySubclaimAdmin = () => {
  return canModifyAdmin() || can(SubclaimAdminFullAccess)
}

/**
 * Checks if user has full access permission to class of business administration screen
 * (explicitly or by having full access permission to all administartion screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canModifyClassOfBusinessAdmin = () => {
  return canModifyAdmin() || can(ClassOfBusinessAdminFullAccess)
}

/**
 * Checks if user has full access permission to class of business group administration screen
 * (explicitly or by having full access permission to all administartion screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canModifyClassOfBusinessGroupAdmin = () => {
  return canModifyAdmin() || can(ClassOfBusinessGroupAdminFullAccess)
}

/**
 * Checks if user has full access permission to master data administration screen
 * (explicitly or by having full access permission to all administartion screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canModifyMasterDataAdmin = () => {
  return canModifyAdmin() || can(MasterDataAdminFullAccess)
}

/**
 * Checks if user has full access permission to task RAG status administration screen
 * (explicitly or by having full access permission to all administartion screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canModifyTaskRagAdmin = () => {
  return canModifyAdmin() || can(TaskRagAdminFullAccess)
}

/**
 * Checks if user has full access permission to participants administration screen
 * (explicitly or by having full access permission to all administartion screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canModifyPaymentsAdmin = () => {
  return canModifyAdmin() || can(PaymentsAdminFullAccess)
}

/**
 * Checks if user has full access permission to participants administration screen
 * (explicitly or by having full access permission to all administartion screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canModifyParticipantsAdmin = () => {
  return canModifyAdmin() || can(ParticipantsAdminFullAccess)
}

/**
 * Checks if user has full access permission to alerts administration screen
 * (explicitly or by having full access permission to all administartion screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canModifyAlertsAdmin = () => {
  return canModifyAdmin() || can(AlertsAdminFullAccess)
}

/**
 * Checks if user has full access permission to holiday administration screen
 * (explicitly or by having full access permission to all administartion screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canModifyHolidayAdmin = () => {
  return canModifyAdmin() || can(HolidayAdminFullAccess)
}

/**
 * Checks if user has full access permission to payment email administration screen
 * (explicitly or by having full access permission to all administartion screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canModifyPaymentEmailAdmin = () => {
  return canModifyAdmin() || can(PaymentEmailAdminFullAccess)
}

/**
 * Checks if user has full access permission to configuration screens
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canModifyConfigurationAdmin = () => {
  return can(ConfigAdminFullAccess)
}

/* Checks if user has full access permission to users administration screen
 * (explicitly or by having full access permission to all administration screens).
 * @returns {Boolean} Returns `true` if user has access, else `false`.
 */
const canModifyUsersAdmin = () => {
  return canModifyAdmin() || can(UsersAdminFullAccess)
}

/**
 * Checks if user has full access permission to roles administration screen
 * (explicitly or by having full access permission to all administartion screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canModifyRolesAdmin = () => {
  return canModifyAdmin() || can(RolesAdminFullAccess)
}

/**
 * Checks if user has full access permission to org administration screen
 * (explicitly or by having full access permission to all administration screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canModifyOrgAdmin = () => {
  return canModifyAdmin() || can(OrgAdminFullAccess)
}

/**
 * Checks if user has full access permission to approval limits administration screen
 * (explicitly or by having full access permission to all administartion screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canModifyApprovalLimitsAdmin = () => {
  return canModifyAdmin() || can(ApprovalLimitsAdminFullAccess)
}

/**
 * Checks if user has full access permission to workflow administration screen
 * (explicitly or by having full access permission to all administation screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canModifyWorkflowAdmin = () => {
  return canModifyAdmin() || can(WorkflowAdminFullAccess)
}

/**
 * Checks if user has full access permission to triage administration screen
 * (explicitly or by having full access permission to all administation screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canModifyTriageAdmin = () => {
  return canModifyAdmin() || can(TriageAdminFullAccess)
}

/**
 * Checks if user has full access permission to email templates administration screen
 * (explicitly or by having full access permission to all administation screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canModifyEmailTemplatesAdmin = () => {
  return canModifyAdmin() || can(EmailTemplatesAdminFullAccess)
}

/* Admin read access checks */

/**
 * Checks if user can access all administration screens.
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canReadAdmin = () => {
  return can(AllAdminReadAccess) || canModifyAdmin()
}

/**
 * Checks if user can access options administration screen
 * (explicitly or by having access permission to all administartion screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessOptionsAdmin = () => {
  return canReadAdmin() || can(OptionsAdminReadAccess) || can(OptionsAdminFullAccess)
}

/**
 * Checks if user can access sunclaim administration screen
 * (explicitly or by having access permission to all administartion screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessSubclaimAdmin = () => {
  return canReadAdmin() || can(SubclaimAdminReadAccess) || can(SubclaimAdminFullAccess)
}

/**
 * Checks if user can access class of business administration screen
 * (explicitly or by having access permission to all administartion screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessClassOfBusinessAdmin = () => {
  return (
    canReadAdmin() ||
    can(ClassOfBusinessAdminReadAccess) ||
    can(ClassOfBusinessAdminFullAccess)
  )
}

/**
 * Checks if user can access class of business group administration screen
 * (explicitly or by having access permission to all administartion screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessClassOfBusinessGroupAdmin = () => {
  return (
    canReadAdmin() ||
    can(ClassOfBusinessGroupAdminReadAccess) ||
    can(ClassOfBusinessGroupAdminFullAccess)
  )
}

/**
 * Checks if user can access master data administration screen
 * (explicitly or by having access permission to all administartion screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessMasterDataAdmin = () => {
  return (
    canReadAdmin() || can(MasterDataAdminFullAccess) || can(MasterDataAdminReadAccess)
  )
}

/**
 * Checks if user can access task RAG status administration screen
 * (explicitly or by having access permission to all administartion screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessTaskRagAdmin = () => {
  return canReadAdmin() || can(TaskRagAdminReadAccess) || can(TaskRagAdminFullAccess)
}

/**
 * Checks if user can access payments administration screen
 * (explicitly or by having access permission to all administartion screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessPaymentsAdmin = () => {
  return canReadAdmin() || can(PaymentsAdminReadAccess) || can(PaymentsAdminFullAccess)
}

/**
 * Checks if user can access participants administration screen
 * (explicitly or by having access permission to all administartion screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */

const canAccessParticipantsAdmin = () => {
  return (
    canReadAdmin() || can(ParticipantsAdminReadAccess) || can(ParticipantsAdminFullAccess)
  )
}

/**
 * Checks if user can access alerts administration screen
 * (explicitly or by having access permission to all administartion screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessAlertsAdmin = () => {
  return canReadAdmin() || can(AlertsAdminReadAccess) || can(AlertsAdminFullAccess)
}

/**
 * Checks if user can access holiday administration screen
 * (explicitly or by having access permission to all administartion screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessHolidayAdmin = () => {
  return canReadAdmin() || can(HolidayAdminReadAccess) || can(HolidayAdminFullAccess)
}

/**
 * Checks if user can access configuration screens
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessConfigurationAdmin = () => {
  return can(ConfigAdminReadAccess) || can(ConfigAdminFullAccess)
}

/**
 * Checks if user has read access permission to users administration screen
 * (explicitly or by having read access permission to all administration screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessUsersAdmin = () => {
  return canReadAdmin() || can(UsersAdminReadAccess) || can(UsersAdminFullAccess)
}

/**
 * Checks if user can access roles administration screen
 * (explicitly or by having access permission to all administartion screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessRolesAdmin = () => {
  return canReadAdmin() || can(RolesAdminReadAccess) || can(RolesAdminFullAccess)
}

/**
 * Checks if user has read access permission to org administration screen
 * (explicitly or by having read access permission to all administration screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessOrgAdmin = () => {
  return canReadAdmin() || can(OrgAdminFullAccess) || can(OrgAdminReadAccess)
}

/**
 * Checks if user can access approval limits administration screen
 * (explicitly or by having access permission to all administartion screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessApprovalLimitsAdmin = () => {
  return (
    canReadAdmin() ||
    can(ApprovalLimitsAdminReadAccess) ||
    can(ApprovalLimitsAdminFullAccess)
  )
}

/**
 * Checks if user can access workflow administration screen
 * (explicitly or by having access permission to all administartion screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessWorkflowAdmin = () => {
  return canReadAdmin() || can(WorkflowAdminReadAccess) || can(WorkflowAdminFullAccess)
}

/**
 * Checks if user can access workflow administration screen
 * (explicitly or by having access permission to all administartion screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessTriageAdmin = () => {
  return canReadAdmin() || can(TriageAdminReadAccess) || can(TriageAdminFullAccess)
}

/**
 * Checks if user can access workflow administration screen
 * (explicitly or by having access permission to all administartion screens).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessEmailTemplatesAdmin = () => {
  return (
    canReadAdmin() ||
    can(EmailTemplatesAdminReadAccess) ||
    can(EmailTemplatesAdminFullAccess)
  )
}

/* Claim search and reports access checks */

/**
 * Checks if user has read access permission to all reports.
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessAllReports = () => {
  return can(AllReportAccess)
}

/**
 * Checks if user has read access permission to claim search
 * (explicitly or by having read access permission to all reports).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessClaimSearch = () => {
  return can(ClaimSearchAccess) || canAccessAllReports()
}

/**
 * Checks if user has read access permission to claim report
 * (explicitly or by having read access permission to all reports).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessClaimReport = () => {
  return can(ClaimsReportAccess) || canAccessAllReports()
}

/**
 * Checks if user has read access permission to task report
 * (explicitly or by having read access permission to all reports).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessTaskReport = () => {
  return can(TaskReportAccess) || canAccessAllReports()
}

/**
 * Checks if user has read access permission to payment report
 * (explicitly or by having read access permission to all reports).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessPaymentReport = () => {
  return can(PaymentReportAccess) || canAccessAllReports()
}

/**
 * Checks if user has read access permission to financial reports
 * (explicitly or by having read access permission to all reports).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessFinancialReports = () => {
  return can(FinancialReportAccess) || canAccessAllReports()
}

/**
 * Checks if user has read access permission to audit reports
 * (explicitly or by having read access permission to all reports).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessAuditReports = () => {
  return (
    can(AccessAuditReportAccess) ||
    can(AdminAuditReportAccess) ||
    can(ClaimAuditReportAccess) ||
    can(EmailAuditReportAccess) ||
    canAccessAllReports()
  )
}

/**
 * Checks if user has read access permission to audit reports
 * (explicitly or by having read access permission to all reports).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessEmailAuditReports = () => {
  return can(EmailAuditReportAccess) || canAccessAllReports()
}

/**
 * Checks if user has read access permission to audit reports
 * (explicitly or by having read access permission to all reports).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessClaimAuditReports = () => {
  return can(ClaimAuditReportAccess) || canAccessAllReports()
}

/**
 * Checks if user has read access permission to audit reports
 * (explicitly or by having read access permission to all reports).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessAdminAuditReports = () => {
  return can(AdminAuditReportAccess) || canAccessAllReports()
}

/**
 * Checks if user has read access permission to audit reports
 * (explicitly or by having read access permission to all reports).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessAccessAuditReports = () => {
  return can(AccessAuditReportAccess) || canAccessAllReports()
}

/**
 * Checks if user has read access permission to email task details report
 * (explicitly or by having read access permission to all reports).
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAssessEmailTaskDetailsReport = () => {
  return can(EmailTaskDetailsReportAccess) || canAccessAllReports()
}

/**
 * Checks if user has read access permission to manager email inbox
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessManagerEmailInbox = () => {
  return can(ManagerEmailInboxAccess)
}

/**
 * Checks if user has read access permission to outstanding email inbox
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessOutstandingEmailInbox = () => {
  return can(OutstandingEmailInboxAccess)
}

/**
 * Checks if user has read access to at least one email inbox.
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessAnyEmailInbox = () => {
  return (
    can(ManagerEmailInboxAccess) ||
    can(EmailInboxAccess) ||
    can(OutstandingEmailInboxAccess)
  )
}

/**
 * Checks if user has read access to at least one report.
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessAnyReport = () => {
  return (
    can(ClaimsReportAccess) ||
    can(TaskReportAccess) ||
    can(FinancialReportAccess) ||
    can(PaymentReportAccess) ||
    can(AccessAuditReportAccess) ||
    can(AdminAuditReportAccess) ||
    can(ClaimAuditReportAccess) ||
    can(EmailAuditReportAccess) ||
    can(EmailTaskDetailsReportAccess) ||
    can(AllReportAccess)
  )
}

/**
 * Checks if user has read access to at least one of the administration pages.
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessAnyAdministrationPage = () => {
  return (
    canReadAdmin() ||
    canAccessUsersAdmin() ||
    canAccessRolesAdmin() ||
    canAccessMasterDataAdmin() ||
    canAccessClassOfBusinessAdmin() ||
    canAccessClassOfBusinessGroupAdmin() ||
    canAccessOrgAdmin() ||
    canAccessApprovalLimitsAdmin() ||
    canAccessSubclaimAdmin() ||
    canAccessOptionsAdmin() ||
    canAccessTaskRagAdmin() ||
    canAccessPaymentsAdmin() ||
    canAccessParticipantsAdmin() ||
    canAccessAlertsAdmin() ||
    canAccessHolidayAdmin() ||
    canAccessWorkflowAdmin() ||
    canAccessTriageAdmin() ||
    canAccessEmailTemplatesAdmin()
  )
}

/**
 * Checks if user has access permission to dashboard "All" toggle.
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessDashboardAllTogle = () => {
  return can(DashboardAllToggleAccess)
}

/**
 * Checks if user has access permission to dashboard "Custom" toggle.
 * @returns {boolean} Returns `true` if user has access, else `false`.
 */
const canAccessDashboardCustomTogle = () => {
  return can(DashboardCustomToggleAccess)
}

export default ability
export {
  updateAbility,
  can,
  canModifyClaim,
  canReadClaim,
  canAccessClaimSearch,
  canAccessClaimReport,
  canAccessTaskReport,
  canAccessPaymentReport,
  canAccessFinancialReports,
  canAccessAuditReports,
  canAccessAccessAuditReports,
  canAccessAdminAuditReports,
  canAccessClaimAuditReports,
  canAccessEmailAuditReports,
  canAssessEmailTaskDetailsReport,
  canAccessAnyReport,
  canModifyAdmin,
  canModifyMasterDataAdmin,
  canModifyOptionsAdmin,
  canModifyTaskRagAdmin,
  canModifySubclaimAdmin,
  canModifyAlertsAdmin,
  canModifyPaymentsAdmin,
  canModifyParticipantsAdmin,
  canModifyClassOfBusinessAdmin,
  canModifyClassOfBusinessGroupAdmin,
  canModifyHolidayAdmin,
  canModifyConfigurationAdmin,
  canModifyPaymentEmailAdmin,
  canModifyUsersAdmin,
  canModifyRolesAdmin,
  canModifyOrgAdmin,
  canModifyApprovalLimitsAdmin,
  canModifyWorkflowAdmin,
  canModifyTriageAdmin,
  canModifyEmailTemplatesAdmin,
  canReadAdmin,
  canAccessOptionsAdmin,
  canAccessMasterDataAdmin,
  canAccessTaskRagAdmin,
  canAccessSubclaimAdmin,
  canAccessAlertsAdmin,
  canAccessPaymentsAdmin,
  canAccessParticipantsAdmin,
  canAccessClassOfBusinessAdmin,
  canAccessClassOfBusinessGroupAdmin,
  canAccessHolidayAdmin,
  canAccessConfigurationAdmin,
  canAccessUsersAdmin,
  canAccessRolesAdmin,
  canAccessOrgAdmin,
  canAccessApprovalLimitsAdmin,
  canAccessWorkflowAdmin,
  canAccessTriageAdmin,
  canAccessAnyAdministrationPage,
  canAccessEmailTemplatesAdmin,
  canAccessDashboardAllTogle,
  canAccessDashboardCustomTogle,
  canAccessAnyEmailInbox,
  canAccessManagerEmailInbox,
  canAccessOutstandingEmailInbox,
}
