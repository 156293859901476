/**
 * A function to create a tooltip directive.
 * @param {import('vue').App} app - The Vue application instance.
 */
const addTooltipDirective = (app) => {
  app.directive('tooltip', {
    /**
     * Called after the element is inserted into the parent DOM.
     * @param {Element} el - The element on which the directive is applied.
     * @param {import('vue').DirectiveBinding} binding - An object containing various information about the directive.
     */
    mounted(el, binding) {
      addTooltipToElement(el, binding)
    },
    /**
     * Called after the component's VNode and the VNodes of its children have updated.
     * @param {Element} el - The element on which the directive is applied.
     * @param {import('vue').DirectiveBinding} binding - An object containing various information about the directive.
     */
    updated(el, binding) {
      addTooltipToElement(el, binding)
    },
  })
}

/**
 * A function to initialize the tooltip.
 * @param {Element} el - The element on which the directive is applied.
 * @param {import('vue').DirectiveBinding} binding - An object containing various information about the directive.
 */
function addTooltipToElement(el, binding) {
  let position = binding.arg || 'top'
  let tooltipText = binding.value || 'Tooltip text'
  el.setAttribute('position', position)
  el.setAttribute('tooltip', tooltipText)
}

export default addTooltipDirective
