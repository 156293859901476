import { defineStore, acceptHMRUpdate } from 'pinia'
import VueJwtDecode from 'vue-jwt-decode'
import { useAuthService } from '@/services/api/useAuthService'
import { useRequest } from '@/services/api/useRequest'
import { setConfig, getConfigurationData } from '@/services/useConfig'
import { Urls } from '@/services/api/Urls'
import { updateAbility } from '@/services/ability'
import { Stores } from '@/stores/Stores'
import { RouteNames, RouteItems } from '@/router/routes'
import { useLogger } from '@/services/useLogger'
import { SourceType } from './models/SourceType'

export const useApplicationStore = defineStore(Stores.ApplicationStore, {
  state: () => {
    const stateVal = {
      currentUser: {
        name: null,
        fullName: null,
        sid: null,
        id: null,
        email: null,
        phoneNumber: null,
        countryCode: null,
      },
      token: { data: null, expired: false },
      dragAndDropEnabled: false,
      documentsActive: false,
      tasksActive: false,
      subclaimOverviewActive: false,
      applicationInitializedWaitHandle: Promise.resolve(),
      isNotificationsUnavailable: false,
      notificationsCount: {
        count: 0,
        notifications: [
          {
            id: null,
            messageDetails: null,
            redirectUrl: null,
            type: null,
            createdDate: null,
          },
        ],
      },
      applicationInitializedWaitHandleResolve: () => {},
      applicationInitializedWaitHandleReject: (reason) => {
        throw reason
      },
      lightMode: true,
      isBureauClaimOverview: false,
    }

    stateVal.applicationInitializedWaitHandle = new Promise((resolve, reject) => {
      stateVal.applicationInitializedWaitHandleResolve = resolve
      stateVal.applicationInitializedWaitHandleReject = reject
    })

    return stateVal
  },
  getters: {
    username: (state) => state.currentUser.name,
    getCurrentUser: (state) => state.currentUser,
    getCurrentUserEmail: (state) => state.currentUser.email,
    getCurrentUserId: (state) => state.currentUser.id,
    accessToken: (state) => state.token.data,
    isSessionExpired: (state) => state.token.expired,
    isDragAndDropEnabled: (state) => state.dragAndDropEnabled,
    isDocumentsActive: (state) => state.documentsActive,
    isTasksActive: (state) => state.tasksActive,
    isSubclaimOverviewActive: (state) => state.subclaimOverviewActive,
    isLightModeVisible: (state) => state.lightMode == true,
    isDarkMode: (state) => state.lightMode == false,
    getNotifications: (state) => state.notificationsCount.notifications,
    getSource: (state) =>
      state.isBureauClaimOverview ? SourceType.bureau.name : SourceType.nonBureau.name,
  },
  actions: {
    toogleMode() {
      this.lightMode = !this.lightMode

      const htmlElement = document.documentElement
      htmlElement.classList.toggle('is-dark')

      if (this.lightMode) {
        window.localStorage.setItem('theme', 'light')
        return
      }

      window.localStorage.setItem('theme', 'dark')
    },
    setSessionExpired() {
      this.token.expired = true
    },
    setToken(accessData) {
      this.token = {
        data: accessData.access_token,
        expired: accessData.expired,
      }
    },
    enableDragAndDrop() {
      this.dragAndDropEnabled = true
    },
    disableDragAndDrop() {
      this.dragAndDropEnabled = false
    },
    setDocumentsActive(bool) {
      this.documentsActive = bool
    },
    setTasksActive(bool) {
      this.tasksActive = bool
    },
    setSubclaimOverviewActive(bool) {
      this.subclaimOverviewActive = bool
    },
    setNotificationsUnavailable(bool) {
      this.isNotificationsUnavailable = bool
    },
    async initApplication({ router }) {
      this.setTheme()
      this.setRedirectToPath()

      const log = useLogger()

      try {
        this.setConfiguration()

        const { get } = useRequest()
        const {
          getAccessData,
          login,
          tokenExpired,
          signinRedirectCallback,
          signinSilentCallback,
        } = useAuthService()

        if (this.isSilentRenew()) {
          await signinSilentCallback()
        }

        const code = this.getResponceCode()

        if (code) {
          await signinRedirectCallback()
          await this.redirectAfterLogginIn(router)
        }

        const accessData = await getAccessData()
        const status = tokenExpired(accessData)
        log.info('token expiration status: ', status)

        if (status === true || status === undefined) {
          await login()
          return LoginStatus.EXPIRED
        }

        this.token = {
          data: accessData.access_token,
          expired: accessData.expired,
        }

        this.setUsersPermissions()

        const user = await get(Urls.users.me)

        this.currentUser = {
          sid: accessData.profile.sub,
          id: user?.id,
          name: user?.name,
          fullName: user?.fullName,
          email: user?.email,
          phoneNumber: user?.phoneNumber,
          countryCode: user?.country,
        }

        this.applicationInitializedWaitHandleResolve()

        return LoginStatus.DONE
      } catch (err) {
        log.error(err)
        this.applicationInitializedWaitHandleReject(err)

        return LoginStatus.EXPIRED
      }
    },
    setTheme() {
      const theme = window.localStorage.getItem('theme')
      const htmlElement = document.documentElement

      if (theme && theme === 'dark') {
        !htmlElement.classList.contains('is-dark') && htmlElement.classList.add('is-dark')
        this.lightMode = false
      } else {
        htmlElement.classList.contains('is-dark') &&
          htmlElement.classList.remove('is-dark')
        this.lightMode = true
      }
    },
    setRedirectToPath() {
      const path = window.location.href.split(window.location.host)[1]
      const isPathAValidRouteForRedirect =
        path !== '/' &&
        !!Object.values(RouteItems)
          .filter(
            (x) =>
              ![
                RouteNames.SilentRenew,
                RouteNames.RedirectCallback,
                RouteNames.NonBureauTasks,
              ].includes(x.name)
          )
          .find((x) => path.startsWith(x.path))

      if (isPathAValidRouteForRedirect) sessionStorage.setItem('redirectTo', path)
      this.isBureauClaimOverview = path.startsWith(
        RouteItems[RouteNames.BureauClaimOverview].path
      )
    },
    async redirectAfterLogginIn(router) {
      const redirectTo = sessionStorage.getItem('redirectTo')
      sessionStorage.removeItem('redirectTo')

      if (!redirectTo) {
        router.push({ name: RouteNames.NonBureauTasks })
        return
      }

      const isClaimOverviewRoute = redirectTo.startsWith(
        RouteItems[RouteNames.ClaimOverview].path
      )
      const isBureauClaimOverviewRoute = redirectTo.startsWith(
        RouteItems[RouteNames.BureauClaimOverview].path
      )

      if (!isClaimOverviewRoute && !isBureauClaimOverviewRoute) {
        router.push(redirectTo)
        return
      }

      const baseUrl = import.meta.env.VITE_BASE_URL
      const parsedUrl = new URL(baseUrl + redirectTo)
      const claimId = parsedUrl.searchParams.get('claimId')
      const taskId = parsedUrl.searchParams.get('taskId') ?? undefined

      if (isClaimOverviewRoute) {
        const { get } = useRequest()

        try {
          await get(Urls.claims.accessCheck(claimId))

          router.push({
            name: RouteNames.ClaimOverview,
            query: {
              claimId: claimId,
              taskId: taskId,
            },
          })
        } catch (e) {
          router.push({ name: RouteNames.NonBureauTasks })
        }
      } else {
        router.push({
          name: RouteNames.BureauClaimOverview,
          query: {
            claimId: claimId,
            taskId: taskId,
            noBorder: true,
            nr: true,
          },
        })
      }
    },
    setUsersPermissions() {
      const log = useLogger()

      try {
        const decoded = VueJwtDecode.decode(this.token.data)?.extension_permissions
        const permissions = decoded?.split(';') ?? []
        updateAbility(permissions)
      } catch (err) {
        err.handled = true
        log.error('Failed to retrieve user permissions from the token', err)
      }
    },
    setUserNotificationsCount(notificationsCount) {
      this.notificationsCount = {
        count: notificationsCount.count,
        notifications: notificationsCount.notifications,
      }
    },
    isSilentRenew() {
      return window.location.pathname === RouteNames.SilentRenew
    },

    getResponceCode() {
      const url = new URL(window.location.href)
      const code = url.searchParams.get('code')
      return code
    },

    setConfiguration() {
      const log = useLogger()
      log.info('Running development mode')

      let data = getConfigurationData()

      setConfig(data.config)

      return data
    },

    async logout(isIdleTimeout) {
      const { logout } = useAuthService()
      const loginHint = VueJwtDecode.decode(this.token.data)?.login_hint
      await logout(isIdleTimeout, loginHint)
      this.$reset()
    },
  },
})

export const LoginStatus = {
  EXPIRED: 'EXPIRED',
  DONE: 'DONE',
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useApplicationStore, import.meta.hot))
}
