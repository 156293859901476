<script setup>
const props = defineProps({
  items: Array,
  separator: String,
  align: String,
  withIcons: Boolean,
})
</script>

<template>
  <nav
    role="navigation"
    class="breadcrumb"
    aria-label="breadcrumbs"
    itemscope
    itemtype="https://schema.org/BreadcrumbList"
    :class="[`has-${props.separator}-separator`, props.align && `is-${props.align}`]"
  >
    <ul>
      <li
        v-for="(item, key) in props.items"
        :key="key"
        :aria-current="key === items.length - 1 ? 'page' : undefined"
        itemprop="itemListElement"
        itemscope
        itemtype="https://schema.org/ListItem"
      >
        <slot name="breadcrumb-item" :item="item" :index="key">
          <span v-if="item.to">
            <span
              v-if="item.notClickable"
              style="font-family: var(--font); color: var(--orange); padding: 0 0.75em"
            >
              <meta
                v-if="item.hideLabel && props.withIcons && !!item.icon"
                itemprop="name"
                :content="item.label"
              />
              <span v-else itemprop="name">
                <slot name="breadcrumb-item-label" :item="item" :index="key">
                  {{ item.label }}
                </slot>
              </span>

              <meta itemprop="position" :content="`${key + 1}`" />
            </span>
            <span v-else>
              <RouterLink
                class="breadcrumb-item"
                itemprop="item"
                :to="item.to"
                :event="item.notClickable ? '' : 'click'"
              >
                <meta
                  v-if="item.hideLabel && props.withIcons && !!item.icon"
                  itemprop="name"
                  :content="item.label"
                />
                <span v-else itemprop="name">
                  <slot name="breadcrumb-item-label" :item="item" :index="key">
                    {{ item.label }}
                  </slot>
                </span>

                <meta itemprop="position" :content="`${key + 1}`" />
              </RouterLink>
            </span>
          </span>
          <a
            v-else-if="item.link"
            class="breadcrumb-item"
            itemprop="item"
            :href="item.link"
          >
            <span
              v-if="props.withIcons && !!item.icon"
              class="icon is-small"
              :class="[item.hideLabel && props.withIcons && !!item.icon && 'is-solo']"
            >
              <i aria-hidden="true" class="iconify" :data-icon="item.icon"></i>
            </span>
            <meta
              v-if="item.hideLabel && props.withIcons && !!item.icon"
              itemprop="name"
              :content="item.label"
            />
            <span v-else itemprop="name">
              <slot name="breadcrumb-item-label" :item="item" :index="key">
                {{ item.label }}
              </slot>
            </span>

            <meta itemprop="position" :content="`${key + 1}`" />
          </a>
          <span v-else class="breadcrumb-item">
            <span
              v-if="props.withIcons && !!item.icon"
              class="icon is-small"
              :class="[item.hideLabel && props.withIcons && !!item.icon && 'is-solo']"
            >
              <i aria-hidden="true" class="iconify" :data-icon="item.icon"></i>
            </span>
            <meta
              v-if="item.hideLabel && props.withIcons && item.icon"
              itemprop="name"
              :content="item.label"
            />
            <span v-else itemprop="name">
              <slot name="breadcrumb-item-label" :item="item" :index="key">
                {{ item.label }}
              </slot>
            </span>

            <meta itemprop="position" :content="`${key + 1}`" />
          </span>
        </slot>
      </li>
    </ul>
  </nav>
</template>
