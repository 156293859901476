import { useApplicationStoreNotPersistant } from '@/stores/ApplicationStoreNotPersistant'
import { useLogger } from '@/services/useLogger'

export const useNavigationRequestSpinner = ({ router }) => {
  const applicationStoreNotPersistant = useApplicationStoreNotPersistant()
  const { log } = useLogger()
  let requestId

  router.beforeEach((to, from) => {
    // in some cases beforeEach quard might be triggered twice
    // especially when using router.push()
    if (from.fullpath === to.fullpath) return
    if (requestId) applicationStoreNotPersistant.requestFinished(requestId)

    requestId = applicationStoreNotPersistant.setIsRequestRunning()
    log('useNavigationRequestSpinner: before navigation', { requestId })
  })

  router.afterEach(() => {
    // in some cases afterEach quard might be triggered twice
    // especially when using router.push()
    if (!requestId) return

    log('useNavigationRequestSpinner: after navigation', { requestId })
    applicationStoreNotPersistant.requestFinished(requestId)
    requestId = null
  })
}
