/// <reference types="vite/client" />
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { useNProgress } from '@/plugins/useNProgress'
import { useNavigationRequestSpinner } from '@/plugins/useNavigationRequestSpinner'
import { useCalendar } from '@/plugins/useCalendar'
import { useVueI18n } from '@/plugins/useVueI18n'
import { createVfm } from 'vue-final-modal'
import { abilitiesPlugin } from '@casl/vue'
import { useLogger } from '@/services/useLogger'
import { useNaviationGuards } from '@/plugins/useNaviationGuards'
import addDirectives from '@/directives/'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from '@/App.vue'
import router from '@/router'
import Toast, { POSITION } from 'vue-toastification'
import ability from '@/services/ability'
import vidle from 'v-idle-3'
import docosoftUI from '@docosoft/docosoft-ui-components'
import '@/plugins/useYupPlugin'
import '@/plugins/veeValidate'
import '@docosoft/docosoft-ui-components/dist/style.css'
import './styles'

const toastOptions = {
  timeout: 2000,
  position: POSITION.TOP_CENTER,
}

const app = createApp(App)
const pinia = createPinia()
const vfm = createVfm()
const log = useLogger()

app.config.errorHandler = (err, instance, info) => {
  if (!err.isHandled) {
    log.error('errorHandler', err, instance, info)
  }
}

app.config.warnHandler = (err, instance, info) => {
  log.warn('warnHandler', err, instance, info)
}

app.config.globalProperties.window = window

pinia.use(piniaPluginPersistedstate)
app.use(docosoftUI)
app.use(pinia)
app.use(router)
app.use(Toast, toastOptions)
app.use(vfm)
app.use(abilitiesPlugin, ability)
app.use(vidle)
addDirectives(app)
useCalendar({ app })
useVueI18n({ app })
// to show nProgress bar when navigating between routes
useNProgress({ router })
// to show request spinner when navigating between routes
useNavigationRequestSpinner({ router })
// permissions checking
useNaviationGuards({ router })

app.mount('#app')
