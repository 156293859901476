// @ts-check
import { RouteNames } from '@/router/routes'
import { formatNumberWithDecimals } from '@/utils/textFormatter'
import { dateFormat } from '@/utils/dateFormatter'
import { ConfigurationType, FieldType } from '@/constants'

export const getPolicyFieldValueFormatter = (/** @type {string} */ fieldType) => {
  if (fieldType === FieldType.Amount) return formatNumberWithDecimals
  if (fieldType === FieldType.Date) return dateFormat
  return null
}

export const getMenuItemName = (name) => {
  if (name === ConfigurationType.ClaimCreation) {
    return RouteNames.CreateNonBureauClaim
  }

  return ''
}
