import { useI18n } from 'vue-i18n'
import { defineStore, acceptHMRUpdate } from 'pinia'
import { Stores } from '@/stores/Stores'

export const getFinancialReportHeaders = () => {
  const { t } = useI18n()
  const subclaimReportLabel = t('pages.reports.financial.subclaimReport')
  const itemReportLabel = t('pages.reports.financial.itemReport')
  const claimReportLabel = t('pages.reports.financial.claimsReport')

  const headers = [
    { value: ReportIds.Claims, label: claimReportLabel },
    { value: ReportIds.Subclaims, label: subclaimReportLabel },
    { value: ReportIds.Items, label: itemReportLabel },
  ]

  return headers
}
export const useFinancialReportsStore = defineStore(Stores.FinancialReportsStore, {
  state: () => ({
    reports: {
      headers: getFinancialReportHeaders(),
    },
    selectedReport: getFinancialReportHeaders()[0].value,
  }),
  getters: {
    getReports: (state) => state.reports,
    getSelectedReport: (state) => state.selectedReport,
  },
  actions: {
    setSelectedReport(key) {
      this.selectedReport = key
    },
    init(isBackToSearch) {
      if (isBackToSearch) return

      this.selectedReport = null
    },
  },
})

export const ReportIds = {
  Subclaims: '1',
  Items: '2',
  Claims: '3',
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFinancialReportsStore, import.meta.hot))
}
