<script setup>
import { formatSentenceCase } from '@/utils/textFormatter'

const props = defineProps({ menuItems: Array, activeSubMenuItem: undefined })
const emits = defineEmits(['toogleSubMenuItem', 'expandSubMenuItem', 'onClose'])

const onClose = () => {
  emits('onClose')
}

const formatTitle = (subItem) => {
  const { name, title } = subItem

  // If the name is neither 'FeatureConfiguration' nor 'SystemConfiguration', or the title is 'DOCOinsights', return the title.
  if (
    (name !== 'FeatureConfiguration' && name !== 'SystemConfiguration') ||
    title === 'DOCOinsights'
  ) {
    return title
  }

  // Otherwise, format the title using formatSentenceCase
  return formatSentenceCase(subItem.title)
}
</script>

<template>
  <div
    class="sidebar-panel is-generic"
    style="z-index: 99; border-right: 1px solid var(--fade-grey-dark-8); width: 241px"
  >
    <div
      class="panel-overlay"
      style="left: 80px"
      tabindex="0"
      @keydown.space.prevent="onClose()"
      @click="onClose()"
    ></div>
    <div class="inner">
      <div v-for="(item, index) in props.menuItems" :key="index">
        <ul v-if="!item.children">
          <li
            class="subsidebar-menu-item"
            @click="() => emits('toogleSubMenuItem', item)"
          >
            <IconTextButton
              :icon="item.icon"
              :name="item.name"
              :title="item.title"
              :is-active="props.activeSubMenuItem === item.name"
            >
              <span class="subsidebar-menu-item__title">{{ item.title }}</span>
            </IconTextButton>
          </li>
        </ul>
        <ul v-if="item.children">
          <span class="subsidebar-menu__feature-title">
            {{ item.title }}
          </span>
          <li
            v-for="subItem in item.children"
            class="subsidebar-menu-item"
            :key="subItem"
            @click="() => emits('expandSubMenuItem', subItem)"
          >
            <IconTextButton
              :icon="subItem.icon"
              :name="subItem.name"
              :title="subItem.title"
              :is-active="props.activeSubMenuItem === item.name"
            >
              <span class="subsidebar-menu-item__title">
                {{ formatTitle(subItem) }}
              </span>
            </IconTextButton>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
