export class SourceType {
  static nonBureau = new SourceType(1, 'NonBureau')
  static bureau = new SourceType(2, 'Bureau')

  constructor(id, name) {
    this.id = id
    this.name = name
  }
  id() {
    return this.id
  }
  name() {
    return this.name
  }
}
