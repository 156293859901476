import { defineStore, acceptHMRUpdate } from 'pinia'
import { Stores } from '@/stores/Stores'
import { useRequest } from '@/services/api/useRequest'
import { Urls } from '@/services/api/Urls'
import { dateStartOfDay, dateEndOfDay } from '@/utils/dateFormatter'

const delta = parseInt(import.meta.env.VITE_MAX_REPORT_COUNT_IN_SINGLE_CALL)

export const useAccessReportStore = defineStore(Stores.AuditAccessReportStore, {
  state: () => ({
    eventTypes: [],
    rows: [],
    defaultSearchModel: {
      date: {
        start: '',
        end: '',
      },
      viewedBy: [],
      eventTypes: [],
      eventDetails: '',
    },
    searchModel: {
      date: {
        start: '',
        end: '',
      },
      viewedBy: [],
      eventTypes: [],
      eventDetails: '',
    },
    gridPagination: {
      total: 0,
      start: 0,
      end: delta,
    },
    currUserFilterSet: null,
  }),
  getters: {
    getRows: (state) => state.rows,
    getSearchModel: (state) => state.searchModel,
    getDefaultSearchModel: (state) => state.defaultSearchModel,
    getPagination: (state) => state.gridPagination,
    getEventTypes: (state) => state.eventTypes.sort((a, b) => a?.localeCompare(b)),
    getCurrUserFilterSet: (state) => state.currUserFilterSet,
  },
  actions: {
    initState(end) {
      this.rows = []
      this.gridPagination = {
        total: 0,
        start: 0,
        end: end ?? delta,
      }
    },
    async init() {
      this.initState()
      await this.setEventTypes()
    },
    async setEventTypes() {
      const { get } = useRequest()
      this.eventTypes = await get(Urls.reporting.audit.accessEventTypes)
    },
    async search(clearCurrentResult = true, end) {
      if (clearCurrentResult) {
        this.initState(end)
      }

      const { post } = useRequest()
      const query = this.prepareQuery()

      const { accessReportResultCount, accessReportResult } = await post(
        Urls.reporting.audit.access,
        query
      )

      this.gridPagination.total = accessReportResultCount

      if (accessReportResultCount > 0 && accessReportResult.length > 0) {
        this.rows = this.rows.concat(accessReportResult)
      }
    },
    loadMoreData() {
      this.gridPagination.start += delta
      this.gridPagination.end += delta
      this.search(false)
    },
    prepareQuery() {
      const searchRequest = this.getSearchRequest()
      const query = {
        start: this.gridPagination.start,
        end: this.gridPagination.end,
        ...searchRequest,
      }

      return query
    },
    getSearchRequest() {
      const searchRequest = {}

      this.addDateRangeToRequestIfSet(this.searchModel.date, 'viewedDate', searchRequest)
      this.addArrayToRequestIfSet(
        [...this.searchModel.viewedBy],
        'viewedBy',
        searchRequest
      )
      this.addArrayToRequestIfSet(
        [...this.searchModel.eventTypes],
        'eventType',
        searchRequest
      )
      this.addStringToRequestIfSet(
        this.searchModel.eventDetails,
        'eventDetail',
        searchRequest
      )

      return searchRequest
    },
    setDefaultSearchModel() {
      this.searchModel = {
        date: { ...this.defaultSearchModel.date },
        viewedBy: [...this.defaultSearchModel.viewedBy],
        eventTypes: [...this.defaultSearchModel.eventTypes],
        eventDetails: this.defaultSearchModel.eventDetails,
      }
    },
    setSearchModel(model) {
      this.searchModel = {
        date: { ...(model.date ?? this.defaultSearchModel.date) },
        viewedBy: [...(model.viewedBy ?? this.defaultSearchModel.viewedBy)],
        eventTypes: [...(model.eventTypes ?? this.defaultSearchModel.eventTypes)],
        eventDetails: model.eventDetails ?? this.defaultSearchModel.eventDetails,
      }
    },
    setCurrUserFilter(name) {
      this.currUserFilterSet = name
    },
    clear() {
      this.rows = []
      this.userNames = []
      this.eventTypes = []
    },
    addStringToRequestIfSet(value, requestKey, request) {
      if (value) {
        request[requestKey] = value
      }
    },
    addDateRangeToRequestIfSet(value, requestKey, request) {
      if (value.start || value.end) {
        request[requestKey] = {
          from: value.start ? dateStartOfDay(value.start) : null,
          to: value.end ? dateEndOfDay(value.end) : null,
        }
      }
    },
    addArrayToRequestIfSet(value, requestKey, request) {
      if (value.length > 0) {
        request[requestKey] = value
      }
    },
    clearFilters() {
      this.setDefaultSearchModel()
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAccessReportStore, import.meta.hot))
}
