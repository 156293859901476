import { createSharedComposable } from '@vueuse/core'
import { useToast } from 'vue-toastification'

const toast = useToast()

export const useNotyf = createSharedComposable(() => {
  return {
    /**
     * Dismisses all toast messages.
     */
    dismissAll: () => {
      toast.clear()
    },
    /**
     * Dismisses a toast message.
     * @param {import('vue-toastification/dist/types/types').ToastID} id Toast message id.
     */
    dismiss: (id) => {
      toast.dismiss(id)
    },
    /**
     * Shows success toast message
     * @param {import('vue-toastification/dist/types/types').ToastContent} payload Toast message payload.
     * @returns {import('vue-toastification/dist/types/types').ToastID | undefined} Toast message id.
     */
    success: (payload) => {
      if (!payload) return

      return toast.success(payload)
    },
    /**
     * Shows error toast message
     * @param {import('vue-toastification/dist/types/types').ToastContent} payload Toast message payload.
     * @returns {import('vue-toastification/dist/types/types').ToastID | undefined} Toast message id.
     */
    error: (payload) => {
      if (!payload) return

      return toast.error(payload)
    },
    /**
     * Shows info toast message
     * @param {import('vue-toastification/dist/types/types').ToastContent} payload Toast message payload.
     * @param {import('vue-toastification/dist/types/types').ToastOptions} [options] Toast message options.
     * @returns {import('vue-toastification/dist/types/types').ToastID | undefined} Toast message id.
     */
    info: (payload, options) => {
      if (!payload) return

      return toast.info(payload, options)
    },
    /**
     * Shows warning toast message
     * @param {import('vue-toastification/dist/types/types').ToastContent} payload Toast message payload.
     * @param {import('vue-toastification/dist/types/types').ToastOptions} [options] Toast message options.
     * @returns {import('vue-toastification/dist/types/types').ToastID | undefined} Toast message id.
     */
    warning: (payload, options) => {
      if (!payload) return

      return toast.warning(payload, options)
    },
  }
})
