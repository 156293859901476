import { UserManager } from 'oidc-client-ts'
import { getConfig } from '@/services/useConfig'
import { useApplicationStore } from '@/stores/ApplicationStore'
import { useLogger } from '@/services/useLogger'

/**
 * @type {UserManager}
 */
let _userManager = null

export const useAuthService = () => {
  const config = getConfig()
  const applicationStore = useApplicationStore()
  const log = useLogger()

  const authSettings = {
    authority: config.authentication.authority,
    client_id: config.authentication.clientId,
    redirect_uri: config.authentication.redirectUrl,
    response_type: config.authentication.responseType,
    scope: config.authentication.scope,
    post_logout_redirect_uri: config.authentication.postLogoutRedirectUrl,
    automaticSilentRenew: true,
    silent_redirect_uri: config.authentication.silentRenewRedirectUrl,
    silentRequestTimeout: 30000,
  }

  if (_userManager === null) {
    _userManager = new UserManager(authSettings)
  }

  _userManager.events.addUserLoaded(function (data) {
    applicationStore.setToken(data)
  })

  _userManager.events.addSilentRenewError(function (err) {
    applicationStore.setSessionExpired()
  })

  const login = async () => {
    return await _userManager.signinRedirect()
  }

  const logout = async (isIdleTimeout, loginHint) => {
    return await _userManager.signoutRedirect({
      extraQueryParams: { isIdleTimeout: isIdleTimeout, logout_hint: loginHint },
    })
  }

  const getAccessData = async () => {
    return await _userManager.getUser()
  }

  const tokenExpired = (accessData) => {
    return accessData?.expired
  }

  const signinRedirectCallback = async () => {
    try {
      const data = await _userManager.signinRedirectCallback()
      applicationStore.setToken(data)
    } catch (ex) {
      log.error(ex)
      await logout()
    }
  }

  const signinSilentCallback = async () => {
    const data = await _userManager.signinSilentCallback()
    applicationStore.setToken(data)
  }

  return {
    login,
    logout,
    getAccessData,
    tokenExpired,
    signinRedirectCallback,
    signinSilentCallback,
  }
}
