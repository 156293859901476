// @ts-check
import isEqual from 'lodash.isequal'

export const isNullish = (value) => value === null || value === undefined
export const isBoolean = (value) => typeof value === 'boolean'
export const isString = (value) => typeof value === 'string'
export const isNumber = (value) => typeof value === 'number'
export const isInteger = (value) => isNumber(value) && parseInt(value) === value
export const isBigInt = (value) => typeof value === 'bigint'
export const isArray = (value) => Array.isArray(value)
export const isObject = (value) => typeof value === 'object'
export const isDate = (date) => {
  if (isNullish(date)) return false

  const parsed = new Date(date)
  return parsed instanceof Date && !isNaN(parsed.valueOf())
}

/**
 * Checks if values are equivalent.
 * @param {any} a First value to compare.
 * @param {any} b Second value to compar
 * @returns {boolean} Returns `true` if the values are equivalent, else `false`.
 */
export const areEqual = (a, b) => {
  return isEqual(a, b)
}

/**
 * Checks if both values are nullish or if are equivalent.
 * @param {any} a First value to compare.
 * @param {any} b Second value to compare.
 * @returns {boolean} Returns `true` if both values are nullish or if the values are equivalent, else `false`.
 */
export const areNullishOrEqual = (a, b) =>
  (isNullish(a) && isNullish(b)) || areEqual(a, b)

/**
 * Sorts an array of objects in ascending order by date.
 * @param {any} a First date value to compare.
 * @param {any} b Second date value to compare.
 * @returns {number} Returns a negative value if ais earlier than b
 * a positive value if a is later than b, and 0 if both values are equal.
 */
export const byDate = (a, b) => new Date(a).getTime() - new Date(b).getTime()
