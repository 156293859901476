export const IsVisible = 'Is Visible'
export const IsMandatory = 'Is Mandatory'
export const IsDisplayInPolicyOverview = 'Display in policy overview'
export const IncludeInClaimReport = 'Include in claim report'
export const IncludeInSubclaimFinancialReport = 'Include in subclaim financial report'
export const IncludeInPaymentReport = 'Include in payment report'
export const IncludeInTaskReport = 'Include in task report'
export const IncludeInItemFinancialReport = 'Include in item financial report'
export const IncludeInClaimFinancialReport = 'Include in claim financials report'
export const FieldType = 'Field Type'
export const IncludeInPolicySearch = 'Include in policy search'
