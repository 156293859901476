import { Dev } from '@/constants'

export const useLogger = () => {
  return {
    info: (...msg) => {
      if (process.env.NODE_ENV === Dev) {
        console.info(...msg)
      }
    },
    warn: (...msg) => {
      if (process.env.NODE_ENV === Dev) {
        console.warn(...msg)
      }
    },
    error: (...msg) => {
      if (process.env.NODE_ENV === Dev) {
        console.error(...msg)
      }
    },
    log: (...msg) => {
      if (process.env.NODE_ENV === Dev) {
        console.log(...msg)
      }
    },
  }
}
