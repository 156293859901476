import { LogLevel, HubConnectionBuilder } from '@microsoft/signalr'
import { getConfig } from '@/services/useConfig'
import { useLogger } from '@/services/useLogger'

export const getSignalrHubs = () => {
  const config = getConfig()
  return config.signalR.hubs
}

export const useSignalR = (hubEndpoint) => {
  const log = useLogger()

  class Logger {
    log(logLevel, message) {
      if (logLevel === LogLevel.Error) log.error(message)
      else if (logLevel === LogLevel.Warning) log.warn(message)
      else if (logLevel === LogLevel.Information) log.info(message)
    }
  }

  const buildConnection = (hubEndpoint) => {
    return new HubConnectionBuilder()
      .withUrl(`${getConfig().api.url}${hubEndpoint}`, { withCredentials: false })
      .configureLogging(new Logger())
      .build()
  }

  const connection = new HubConnectionBuilder()
    .withUrl(`${getConfig().api.url}${hubEndpoint}`, { withCredentials: false })
    .configureLogging(new Logger())
    .build()

  return { connection, buildConnection }
}
