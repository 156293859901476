<script setup>
import { useApplicationStore } from '@/stores/ApplicationStore'

const applicationStore = useApplicationStore()

const props = defineProps({
  menuItems: Array,
  activeMenuItem: String,
  disabledItems: Array,
})
const emits = defineEmits(['toogleMenuItem'])
</script>

<template>
  <li v-for="item in props.menuItems" :key="item.title">
    <IconButton
      :icon="item.icon"
      :name="item.name"
      :title="item.title"
      @onClick="(data) => emits('toogleMenuItem', data)"
      :isActive="props.activeMenuItem === item.name"
      :isDisabled="props.disabledItems.filter((x) => x === item.name).length > 0"
      :tooltipText="item.title"
      tooltipPosition="right"
      height="2rem"
      :color="applicationStore.isDarkMode ? 'var(--bone-white)' : 'var(--title-grey)'"
    />
  </li>
</template>
