// @ts-check
import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'
import { getUserLocale } from '@/utils/browser'
import { useLogger } from '@/services/useLogger'
import en from '@/locale/en.locale'

const defaultLocale = 'en'
const supportedLocale = ['en', 'it']

export const i18n = createI18n({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  messages: { en },
})

export const useVueI18n = ({ app }) => {
  app.use(i18n)
}

/**
 *
 */
export async function setLocaleMessages() {
  const locale = getCurrentLocale()
  const currentLocale = i18n.global.locale.value
  if (locale === currentLocale) return

  const logger = useLogger()
  logger.log('useVueI18n: setLocaleMessages: setting next locale', {
    currentLocale,
    locale,
  })

  try {
    if (locale !== defaultLocale) {
      await loadLocaleMessages(locale)
      i18n.global.locale.value = locale
    } else {
      i18n.global.locale.value = defaultLocale
    }
  } catch (err) {
    logger.warn('setLocaleMessages: error loading locale: ', locale, err)
  }
}

/**
 *
 * @param locale
 */
async function loadLocaleMessages(/** @type {string} */ locale) {
  const { log } = useLogger()
  log('useVueI18n: loadLocaleMessages: downloading messages for locale', { locale })
  const messages = await import(`../locale/${locale}.locale.js`)
  log('useVueI18n: loadLocaleMessages: setting messages')
  i18n.global.setLocaleMessage(locale, messages.default)
  // return nextTick saying that the translation data is ready to be used.
  return nextTick()
}

/**
 *
 * @param locale
 */
function isAvailable(/** @type {string} */ locale) {
  const isAvailable = supportedLocale.includes(locale)

  return isAvailable
}

/**
 *
 */
function getCurrentLocale() {
  const { log } = useLogger()
  const { locale: userLocale } = getUserLocale()
  const isUserLocaleAvailable = isAvailable(userLocale)
  const locale = isUserLocaleAvailable ? userLocale : defaultLocale

  log('useVueI18n: getCurrentLocale', { userLocale, isUserLocaleAvailable, locale })
  return locale
}
