export const ClaimCreation = 'Claim Creation'
export const Notes = 'Notes'
export const ManagerApproval = 'Manager Approval'
export const Payments = 'Payments'
export const Policy = 'Policy'
export const Sanctions = 'Sanctions'
export const EmailClaims = 'Email claims'
export const ClaimReferenceFormat = 'Claim reference format'
export const DOCOinsights = 'DOCOinsights'
export const Inbox = 'Inbox'
