<script setup>
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import Sidebar from '@/components/templates/navigation/sidebar/Sidebar.vue'
import Navbar from '@/components/templates/navigation/navbar/Navbar.vue'
import { useApplicationStore } from '@/stores/ApplicationStore'
import { useLogger } from '@/services/useLogger'

const applicationStore = useApplicationStore()
const log = useLogger()
const noBorder = ref(false)
const route = useRoute()

const handleDragEnter = (event) => {
  event.preventDefault()

  if (applicationStore.isDocumentsActive && !applicationStore.isDragAndDropEnabled) {
    applicationStore.enableDragAndDrop()
  }
}

watch(
  () => applicationStore.isSessionExpired,
  () => {
    log.info('applicationStore.isSessionExpired', applicationStore.isSessionExpired)
  }
)

watch(
  () => route.query,
  () => {
    if (route.query.noBorder) {
      noBorder.value = true
      return
    }

    noBorder.value = false
  }
)
</script>

<template>
  <div class="sidebar-layout" @dragenter="handleDragEnter">
    <PageContentWrapper :noBorder="noBorder">
      <template #navigator>
        <Navbar />
        <Sidebar />
      </template>

      <slot></slot>
    </PageContentWrapper>
  </div>
</template>
