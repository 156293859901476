import { useNotyf } from '@/services/useNotyf'
import { useApplicationStore } from '@/stores/ApplicationStore'
import { useLogger } from '@/services/useLogger'
import { i18n } from '@/plugins/useVueI18n'

export const useErrorHandler = () => {
  const applicationStore = useApplicationStore()
  const notif = useNotyf()
  const log = useLogger()

  const handleFormError = (errors) => {
    const { t } = i18n.global

    notif.dismissAll()
    notif.error(t('common.formErrors'))
    if (errors) log.info('Form errors', errors)
  }

  const handleSpecyficError = ({ error }) => {
    notif.dismissAll()
    notif.error(error)
  }

  const isErrorHandled = ({ error }) => {
    return error.response !== undefined
  }

  const getErrorFromRequest = (error) => {
    if (error.response) {
      if (error.response.data?.title && error.response.data?.errors) {
        return `${error.response.data.title} ${Object.values(
          error.response.data.errors
        ).join(' ')}`
      } else if (error.response.data?.title) {
        return `${error.response.data.title}`
      } else {
        return `${error.response?.data} ${error.response?.status}`
      }
    } else if (error.request) {
      return `${error.request.data}`
    } else {
      return `${error.message}`
    }
  }

  const getErrorForBlobTypeResponse = async (error) => {
    try {
      const text = await error.response.data.text()
      const msg = JSON.parse(text)
      return `${msg.title}`
    } catch (e) {
      log.error('getErrorForBlobTypeResponse', e)

      return `${error.message}`
    }
  }

  const isBlobTypeResponseError = (error) => {
    return (
      error.request?.responseType === 'blob' &&
      error.response?.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().includes('json')
    )
  }

  const handleRequestError = async ({ error }, errorMesssage) => {
    log.error('handleRequestError', error)

    if (error.isHandled) {
      return
    }

    const statusCode = error.request?.status

    if (statusCode === 0) {
      return
    }

    if (statusCode === 401) {
      applicationStore.setSessionExpired()
      return
    }

    if (statusCode === 403) {
      notif.error(`Access is denied.`)
      return
    }

    if (statusCode === 502 || statusCode === 503) {
      errorMesssage
        ? notif.error(`${errorMesssage}. \n${error.response.data}`)
        : notif.error(`Service unavailable. \n${error.response.data}`)

      return
    }

    if (isBlobTypeResponseError(error)) {
      const msg = await getErrorForBlobTypeResponse(error)
      notif.error(msg)
    } else {
      notif.error(getErrorFromRequest(error))
    }
  }

  /**
   * Handles errors and displays them as a toast message.
   * @param {{[errorId: string]: string}[]} errors Form errors.
   */
  const handleAndDisplayFormErrors = (errors) => {
    /** @type {string[]} */
    const errorsFlat = [...new Set(Object.values(errors))]
    const errorMessage = errorsFlat.join('\n')

    notif.error(errorMessage)
  }

  return {
    handleFormError,
    handleSpecyficError,
    handleRequestError,
    getErrorFromRequest,
    isErrorHandled,
    handleAndDisplayFormErrors,
  }
}
