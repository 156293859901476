import { defineStore, acceptHMRUpdate } from 'pinia'
import { useRequest } from '@/services/api/useRequest'
import { Urls } from '@/services/api/Urls'
import { Stores } from '@/stores/Stores'
import { dateStringFormat } from '@/utils/dateFormatter'

const delta = parseInt(import.meta.env.VITE_MAX_REPORT_COUNT_IN_SINGLE_CALL)
const eventTypeToSkipWhenNoChangeFromAndTo = 'Claim Update'

export const useClaimReportStore = defineStore(Stores.AuditClaimReportStore, {
  state: () => ({
    gridPagination: {
      total: 0,
      start: 0,
      end: delta,
    },
    eventTypes: [],
    defaultSearchModel: {
      claimReference: '',
      user: [],
      eventTypes: [],
      eventDetails: '',
      date: {
        start: '',
        end: '',
      },
    },
    searchModel: {
      claimReference: '',
      user: [],
      eventTypes: [],
      eventDetails: '',
      date: {
        start: '',
        end: '',
      },
    },
    currUserFilterSet: null,
    rows: [],
    allRows: [],
  }),
  getters: {
    getPagination: (state) => state.gridPagination,
    getRowsCount: (state) => state.rows.length,
    getRows: (state) => state.rows,
    getEventTypes: (state) => state.eventTypes.sort((a, b) => a?.localeCompare(b)),
    getSearchModel: (state) => state.searchModel,
    getDefaultSearchModel: (state) => state.defaultSearchModel,
    getCurrUserFilterSet: (state) => state.currUserFilterSet,
  },
  actions: {
    initState(end) {
      this.clearSearchResult()
      this.gridPagination = {
        total: 0,
        start: 0,
        end: end ?? delta,
      }
    },
    async init(backToSearch) {
      if (backToSearch) {
        const { requestsWrapper } = useRequest()
        const requests = [this.setEventTypes(), this.search(true, delta)]

        await requestsWrapper(...requests)
      } else {
        this.initState()
        if (!this.currUserFilterSet) this.setDefaultSearchModel()
        await this.setEventTypes()
      }
    },
    async setEventTypes() {
      const { get } = useRequest()
      this.eventTypes = await get(Urls.reporting.audit.claimEventTypes)
    },
    async search(clearCurrentResult = true, end) {
      const { post } = useRequest()

      if (clearCurrentResult) {
        this.initState(end)
      }

      const query = this.prepareQuery()
      const { claimReportResultCount, claimReportResult } = await post(
        Urls.reporting.audit.claim,
        query
      )

      const rowsWithChangeFromTo = this.filterRowsWithChange(claimReportResult)

      this.gridPagination.total = claimReportResultCount
      this.rows = this.rows.concat(rowsWithChangeFromTo)
      this.allRows = this.allRows.concat(claimReportResult)
    },
    loadMoreData() {
      this.gridPagination.start += delta
      this.gridPagination.end += delta

      this.search(false)
    },
    prepareQuery() {
      const searchRequest = this.getSearchRequest()
      const query = {
        start: this.gridPagination.start,
        end: this.gridPagination.end,
        ...searchRequest,
      }

      return query
    },
    setDefaultSearchModel() {
      this.searchModel = {
        claimReference: this.defaultSearchModel.claimReference,
        user: [...this.defaultSearchModel.user],
        eventTypes: [...this.defaultSearchModel.eventTypes],
        eventDetails: this.defaultSearchModel.eventDetails,
        date: { ...this.defaultSearchModel.date },
      }
    },
    setSearchModel(model) {
      this.searchModel = {
        claimReference: model.claimReference ?? this.defaultSearchModel.claimReference,
        user: [...(model.user ?? this.defaultSearchModel.user)],
        eventTypes: [...(model.eventTypes ?? this.defaultSearchModel.eventTypes)],
        eventDetails: model.eventDetails ?? this.defaultSearchModel.eventDetails,
        date: { ...(model.date ?? this.defaultSearchModel.date) },
      }
    },
    addToRequestIfSet(value, requestKey, request) {
      if (value) {
        request[requestKey] = value
      }
    },
    addArrayToRequestIfSet(value, requestKey, request) {
      if (value.length > 0) {
        request[requestKey] = value
      }
    },
    addDateToRequestIfSet(value, requestKey, request) {
      if (value) {
        request[requestKey] = dateStringFormat(value)
      }
    },
    addDateRangeToRequestIfSet(value, requestKey, request) {
      if (value.start || value.end) {
        request[requestKey] = {
          from: value.start ? dateStringFormat(value.start) : null,
          to: value.end ? dateStringFormat(value.end) : null,
        }
      }
    },
    getSearchRequest() {
      const searchRequest = {}

      this.addToRequestIfSet(
        this.searchModel.claimReference,
        'claimReference',
        searchRequest
      )
      this.addArrayToRequestIfSet([...this.searchModel.user], 'changedBy', searchRequest)
      this.addArrayToRequestIfSet(
        [...this.searchModel.eventTypes],
        'eventType',
        searchRequest
      )
      this.addToRequestIfSet(this.searchModel.eventDetails, 'eventDetail', searchRequest)
      this.addDateRangeToRequestIfSet(this.searchModel.date, 'eventDate', searchRequest)

      return searchRequest
    },
    filterRowsWithChange(rows) {
      return rows.filter(
        (x) =>
          x?.eventType !== eventTypeToSkipWhenNoChangeFromAndTo ||
          (x?.eventType === eventTypeToSkipWhenNoChangeFromAndTo &&
            (x.changedFrom?.length > 0 || x.changedTo?.length > 0))
      )
    },
    setCurrUserFilter(name) {
      this.currUserFilterSet = name
    },
    clearSearchResult() {
      this.rows = []
      this.allRows = []
    },
    clear() {
      this.clearSearchResult()
      this.userNames = []
      this.eventTypes = []
    },
    clearFilters() {
      this.setDefaultSearchModel()
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useClaimReportStore, import.meta.hot))
}
