// @ts-check
import { useRequest } from '@/services/api/useRequest'
import { Urls } from '@/services/api/Urls'
import { RouteNames } from '@/router/routes'
import { useLogger } from '@/services/useLogger'
import { useAuthService } from '@/services/api/useAuthService'

export const useAccessLogService = () => {
  const log = useLogger()
  const viewLogsAndRouteNamesMapping = {
    [RouteNames.UserAdministration]: 'User administration viewed',
    [RouteNames.HolidayAdministration]: 'Holiday administration viewed',
    [RouteNames.AlertAdministration]: 'Alerts administration viewed',
    [RouteNames.ParticipantsAdministration]: 'Participant administration viewed',
    [RouteNames.RagStatus]: 'Task RAG administration viewed',
    [RouteNames.OptionsConfigurationAdministration]:
      'Options configuration administration viewed',
    [RouteNames.SubclaimsAdministration]: 'Subclaims and items administration viewed',
    [RouteNames.ApprovalLimitsAdministration]: 'Approval limits administration viewed',
    [RouteNames.OrgStructure]: 'Organization structure administration viewed',
    [RouteNames.RoleAdministration]: 'Role administration viewed',
    [RouteNames.ClassOfBusinessAdministration]: 'Class of business administration viewed',
    [RouteNames.NonBureauTasks]: 'Outstanding Inbox viewed',
    [RouteNames.PendingManagerApprovalTasks]: 'Pending manager approval tasks viewed',
    [RouteNames.EmailTemplatesAdministration]: 'Email templates administration viewed',
    [RouteNames.WorkflowAdministration]: 'Workflow administration viewed',
    [RouteNames.TriageAdministration]: 'Triage administration viewed',
    [RouteNames.ParticipantsExpertsAdministration]:
      'Participant - expert administration viewed',
    [RouteNames.ClassOfBusinessGroupAdministration]:
      'Class of business group administration viewed',
  }

  /**
   * @param {{eventName: string, eventDetails?: string}} param
   * @returns void
   */
  const logEvent = async ({ eventName, eventDetails }) => {
    try {
      const { getAccessData, tokenExpired } = useAuthService()
      const accessData = await getAccessData()

      if (!accessData || tokenExpired(accessData)) {
        log.info('Canceled logging an event due to token expiration.')
        return
      }

      const { backgroundPostRequest } = useRequest()

      backgroundPostRequest(Urls.accessLog.post, { eventName, eventDetails })
    } catch (err) {
      log.error('Error loging view access' + err)
    }
  }

  /**
   * @param {{routeName: string}} param
   * @returns void
   */
  const logByRouteName = ({ routeName }) => {
    const eventName = viewLogsAndRouteNamesMapping[routeName]

    if (eventName) {
      logEvent({ eventName })
    }
  }

  return {
    logEvent,
    logByRouteName,
  }
}
