export const ConfigurationFields = {
  policyReference: { name: 'Policy Reference', inputKey: 'policyReference' },
  classOfBusiness: { name: 'Class of Business', inputKey: 'classOfBusiness' },
  signedLine: { name: 'Signed Line', inputKey: 'signedLine' },
  claimOwner: { name: 'Claim Owner', inputKey: 'claimOwner' },
  dateOfLossFrom: { name: 'Date of Loss From', inputKey: 'dateOfLossFrom' },
  peril: { name: 'Peril', inputKey: 'peril' },
  causeOfLossCategory: {
    name: 'Cause of Loss Category',
    inputKey: 'causeOfLossCategory',
  },
  lossLocation: { name: 'Loss Location', inputKey: 'lossLocation' },
  countryOfLoss: { name: 'Country of loss', inputKey: 'countryOfLoss' },
  lossNarrative: { name: 'Loss Narrative', inputKey: 'lossNarrative' },
  blockClaim: { name: 'Block Claim', inputKey: 'blockClaim' },
  noteSection: { name: 'Notes', inputKey: 'noteSection' },
  notes: { name: 'Notes', inputKey: 'notes' },
  noteCategory: { name: 'Category', inputKey: 'noteCategory' },
  notefavourite: { name: 'Favourite', inputKey: 'notefavourite' },
  noteDescription: { name: 'Note Description', inputKey: 'noteDescription' },
  paymentMethod: { name: 'Payment method', inputKey: 'paymentMethod' },
}

export const getConfigurationFieldNamsByInputKey = (inputKey) => {
  let name = null
  for (let [, value] of Object.entries(ConfigurationFields)) {
    if (value.inputKey === inputKey) {
      name = value.name
    }
  }

  return name
}

export const getInputNameByFieldName = (name) => {
  let inputName = name
  for (const [, value] of Object.entries(ConfigurationFields)) {
    if (value?.name === name) {
      inputName = value?.inputKey
      break
    }
  }

  return inputName
}
