<script setup>
import { dateTimeLocalFormat } from '@/utils/dateFormatter'
import { useNotifications } from '@/components/pages/non-bureau/notifications/useNotifications'

const { navigateToClaim } = useNotifications()

const emit = defineEmits(['toggle'])

const alertTypes = {
  warning: 'Warning',
  info: 'Info',
}

const getIconName = (type) => {
  if (type === alertTypes.warning) {
    return 'mdi:alert-outline'
  }
  if (type === alertTypes.info) {
    return 'mdi:information-outline'
  }
  return ''
}

const props = defineProps({
  item: Object,
})

const goToClaim = () => {
  emit('toggle')
  navigateToClaim(props.item)
}
</script>
<template>
  <div class="notifications-item" v-on:click="goToClaim">
    <span class="notifications-item__details">{{ props.item.messageDetails }}</span>
    <span class="notifications-item__sub-details">
      <Icon
        :class="['iconify', 'icon', `alert-${props.item.type.toLowerCase()}`]"
        :icon="getIconName(props.item.type)"
        height="1.3rem"
      />
      <label>{{ dateTimeLocalFormat(props.item.createdDate) }}</label>
    </span>
  </div>
</template>
<style lang="scss">
.notifications-item {
  padding: 5px !important;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-width: 35rem;
  min-width: 35rem;

  &__details {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 600;
    font-size: 1rem;
  }

  &__sub-details label {
    margin-left: 5px;
    vertical-align: middle;
  }
}

.notifications-item:hover {
  cursor: pointer;
}

.alert-info {
  vertical-align: middle;
}

.alert-warning {
  vertical-align: middle;
}

.is-dark {
  .notifications-item {
    color: var(--bone-white);
  }
}
</style>
