import { POSITION } from 'vue-toastification'
import { useNotyf } from '@/services/useNotyf'
import { useSignalR, getSignalrHubs } from '@/services/useSignalR'
import { useApplicationStore } from '@/stores/ApplicationStore'
import { useNotificationsStore } from '@/stores/notifications/NotificationsStore'

/*
useNotification hook from '@/components/pages/non-bureau/notifications/useNotifications.js'
can't be called inside to get navigateToClaim as router from useRouter is undefined. So
navigateToClaim is passed as a parameter from App.vue
*/
export const useNotifications = (navigateToClaim) => {
  const { buildConnection } = useSignalR()

  const initNotifications = async () => {
    const connection = buildConnection(getSignalrHubs().notifications)
    const applicationStore = useApplicationStore()
    const store = useNotificationsStore()

    try {
      await connection.start()

      connection.on('ReceiveNotificationsCount', (notifications) => {
        const toast = useNotyf()
        const currNotifications = applicationStore.getNotifications

        applicationStore.setUserNotificationsCount(notifications)
        applicationStore.setNotificationsUnavailable(false)

        // Don't show notifications on first load and when users marks notification as read
        if (store.disabled) {
          store.enable()

          return
        }

        notifications.notifications
          .filter((notification) => {
            const foundNotification = currNotifications.findIndex(
              (x) => x.id === notification.id
            )
            return foundNotification === -1
          })
          .forEach((notification) => {
            const toastOptions = {
              position: POSITION.BOTTOM_RIGHT,
              timeout: 5_000,
              onClick: async () => {
                await navigateToClaim(notification)
              },
            }

            const toastContent = notification.messageDetails

            if (notification.type === 'Info') {
              toast.info(toastContent, toastOptions)
              return
            }

            toast.warning(toastContent, toastOptions)
          })
      })

      connection.invoke('JoinUserGroup', applicationStore.username)
    } catch {
      store.enable()
      applicationStore.setNotificationsUnavailable(true)
    }
  }

  const stopNotifications = async () => {
    await buildConnection(getSignalrHubs().notifications).stop()
  }

  return {
    stopNotifications,
    initNotifications,
  }
}
