// @ts-check
import { i18n } from '@/plugins/useVueI18n'
const { t } = i18n.global

export default {
  mixed: {
    default: ({ path }) => t('forms.default', { path }),
    required: ({ path }) => t('forms.required', { path }),
    defined: ({ path }) => t('forms.defined', { path }),
    notNull: ({ path }) => t('forms.notNull', { path }),
    oneOf: ({ path, values }) => t('forms.oneOf', { path, values }),
    notOneOf: ({ path, values }) => t('forms.notOneOf', { path, values }),
  },
  string: {
    length: ({ path }) => t('forms.string.length', { path }),
    min: ({ path, min }) => t('forms.string.min', { path, min }),
    max: ({ path, max }) => t('forms.string.max', { path, max }),
    matches: ({ path, regex }) => t('forms.string.matches', { path, regex }),
    email: ({ path }) => t('forms.string.email', { path }),
    url: ({ path }) => t('forms.string.url', { path }),
    uuid: ({ path }) => t('forms.string.uuid', { path }),
    datetime: ({ path }) => t('forms.string.datetime', { path }),
    datetime_precision: ({ path, precision }) =>
      t('forms.string.datetime_precision', { path, precision }),
    datetime_offset: ({ path }) => t('forms.string.datetime_offset', { path }),
    trim: ({ path }) => t('forms.string.trim', { path }),
    lowercase: ({ path }) => t('forms.string.lowercase', { path }),
    uppercase: ({ path }) => t('forms.string.uppercase', { path }),
  },
  number: {
    min: ({ path, min }) => t('forms.number.min', { path, min }),
    max: ({ path, max }) => t('forms.number.max', { path, max }),
    lessThan: ({ path, less }) => t('forms.number.lessThan', { path, less }),
    moreThan: ({ path, more }) => t('forms.number.moreThan', { path, more }),
    positive: ({ path }) => t('forms.number.positive', { path }),
    negative: ({ path }) => t('forms.number.negative', { path }),
    integer: ({ path }) => t('forms.number.integer', { path }),
  },
  date: {
    min: ({ path, min }) => t('forms.date.min', { path, min }),
    max: ({ path, max }) => t('forms.date.max', { path, max }),
  },
  boolean: {
    isValue: ({ path, value }) => t('forms.boolean.min', { path, value }),
  },
  object: {
    noUnknown: ({ path, unknown }) => t('forms.object.min', { path, unknown }),
  },
  array: {
    min: ({ path, min }) => t('forms.array.min', { path, min }),
    max: ({ path, max }) => t('forms.array.min', { path, max }),
    length: ({ path }) => t('forms.array.min', { path }),
  },
}
