export const getFieldFromConfiguration = (fieldKey, config) => {
  if (config && config.configuration) {
    const fieldItem = config.configuration.find(
      (x) =>
        x.name == fieldKey &&
        x.configType == config.configType &&
        x.section == config.section
    )
    return fieldItem
  }
}

export const isFieldVisibleAndRequired = (fieldKey, configuration) => {
  const fieldItem = getFieldFromConfiguration(fieldKey, configuration)

  if (fieldItem == undefined) {
    return defaultVisibleAndRequired()
  }

  return {
    visible: isSetToBeVisible(fieldItem),
    required: isSetToBeMandatory(fieldItem),
  }
}

const defaultVisibleAndRequired = () => {
  return {
    visible: true,
    required: false,
  }
}

export const isFieldVisible = (fieldKey, configuration) => {
  const fieldItem = getFieldFromConfiguration(fieldKey, configuration)
  if (fieldItem === undefined) {
    return defaultVisibleAndRequired().visible
  }
  return isSetToBeVisible(fieldItem)
}

export const isFieldRequired = (fieldKey, configuration) => {
  const fieldItem = getFieldFromConfiguration(fieldKey, configuration)
  if (fieldItem === undefined) {
    return defaultVisibleAndRequired().required
  }
  return isSetToBeMandatory(fieldItem)
}

export const isNotEmpty = (value) => {
  return value !== '' && value !== undefined && value !== null
}

export const isSetToBeVisible = (fieldItem) => {
  if (fieldItem === undefined) {
    return defaultVisibleAndRequired().visible
  }
  return fieldItem.isVisible?.value === 'true'
}

export const isSetToBeMandatory = (fieldItem) => {
  if (fieldItem === undefined) {
    return defaultVisibleAndRequired().required
  }
  return fieldItem.isMandatory?.value === 'true'
}

export const validateSchema = async (schema, value) => {
  try {
    await schema.validate(value)
    return null
  } catch (err) {
    return capitalizeFirstLetter(err?.errors[0])
  }
}

const capitalizeFirstLetter = (string) => {
  if (string) return string.charAt(0).toUpperCase() + string.slice(1)
  return string
}
