<script setup>
import { MobileNavbar, MobileSubsidebar, User } from '@docosoft/docosoft-ui-components'
import SidebarMenuItems from '@/components/templates/navigation/sidebar/SidebarMenuItems.vue'
import SubsidebarMenuItems from '@/components/templates/navigation/sidebar/SubsidebarMenuItems.vue'
import { useSidebar } from '@/components/templates/navigation/sidebar/useSidebar'
import { useConfigurationStore } from '@/stores/configuration/ConfigurationStore'
import { useApplicationStore } from '@/stores/ApplicationStore'
import { RouteNames } from '@/router/routes'
import Logo from '@/components/templates/logo/Logo.vue'

const {
  activeSubMenuItem,
  toogleSubMenuItem,
  topMenuItems,
  isMobileSidebarOpen,
  bottomMenuItems,
  toogleMenuItem,
  subsiderMenuItems,
  isSubsidebarOpen,
  expandSubMenuItem,
  onCloseSubsiderMenuItems,
} = useSidebar()

const configurationStore = useConfigurationStore()
const applicationStore = useApplicationStore()

const mobileNavbarToogle = () => {
  isMobileSidebarOpen.value = !isMobileSidebarOpen.value
}
</script>
<template>
  <Sidebar @click="onCloseSubsiderMenuItems">
    <template #links>
      <SidebarMenuItems
        :menuItems="topMenuItems"
        @toogle-menu-item="toogleMenuItem"
        :activeMenuItem="configurationStore.selectedMenu"
        :disabledItems="configurationStore.getDisabledSections"
      />
    </template>
    <template #bottom-links>
      <SidebarMenuItems
        :menuItems="bottomMenuItems"
        @toogle-menu-item="toogleMenuItem"
        :activeMenuItem="configurationStore.selectedMenu"
        :disabledItems="configurationStore.getDisabledSections"
      />
    </template>
  </Sidebar>

  <MobileNavbar @toggle="mobileNavbarToogle">
    <template #brand>
      <Logo :linkTo="RouteNames.NonBureauTasks" />
      <div class="brand-end">
        <User
          :username="applicationStore.username ?? ''"
          :email="applicationStore.getCurrentUserEmail ?? ''"
          :logout-label="$t('common.logout')"
          @logout="applicationStore.logout"
        />
      </div>
    </template>
  </MobileNavbar>

  <MobileSidebar :is-open="isMobileSidebarOpen">
    <template #links>
      <SidebarMenuItems
        :menuItems="topMenuItems"
        @toogle-menu-item="toogleMenuItem"
        :activeMenuItem="configurationStore.selectedMenu"
        :disabledItems="configurationStore.getDisabledSections"
      />
    </template>
    <template #bottom-links>
      <SidebarMenuItems
        :menuItems="bottomMenuItems"
        @toogle-menu-item="toogleMenuItem"
        :activeMenuItem="configurationStore.selectedMenu"
        :disabledItems="configurationStore.getDisabledSections"
      />
    </template>
  </MobileSidebar>

  <SubsidebarMenuItems
    v-if="isSubsidebarOpen"
    :menuItems="subsiderMenuItems.data"
    @toogle-sub-menu-item="toogleSubMenuItem"
    @expand-sub-menu-item="expandSubMenuItem"
    @onClose="onCloseSubsiderMenuItems"
    :activeSubMenuItem="activeSubMenuItem"
  />

  <Transition name="slide-x">
    <MobileSubsidebar
      v-if="isSubsidebarOpen"
      :menuItems="subsiderMenuItems.data"
      @toogle-sub-menu-item="toogleSubMenuItem"
      @expand-sub-menu-item="expandSubMenuItem"
      :activeSubMenuItem="activeSubMenuItem"
    />
  </Transition>
</template>
