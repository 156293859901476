import { isValidDate, dateFormat } from '@/utils/dateFormatter'
import { isNullish } from '@/utils/comparators'

export const formatUppercaseSeparatedString = (value) => {
  if (!value) {
    return ''
  }
  return value.replace(/([A-Z][A-Z]?[A-Z]?[A-Z]?)/g, ' $1').trim()
}

export const formatSentenceCase = (value) => {
  return value.toLowerCase().charAt(0).toUpperCase() + value.slice(1).toLowerCase()
}

export const formatSnakeCase = (value) => {
  return value.toLowerCase().replace(/\s+/g, '_')
}

export const formatDecimal = (value) => {
  if (value) {
    return parseFloat(value).toFixed(2)
  }
  return value
}

export const formatNumberWithDecimals = (value, decimalPositions) => {
  return `${new Intl.NumberFormat('en-GB', {
    minimumFractionDigits: decimalPositions ?? 2,
    maximumFractionDigits: decimalPositions ?? 2,
  }).format(value)}`
}

export const formatNumberWithDecimalsAndCurrencySymbol = (
  value,
  decimalPositions,
  currencySymbol
) => {
  return `${currencySymbol || ''} ${formatNumberWithDecimals(value, decimalPositions)}`
}

export const formatNumberWithDecimalsAndCurrency = (
  value,
  decimalPositions,
  currency
) => {
  if (!currency) return formatNumberWithDecimals(value, decimalPositions)

  return `${new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: decimalPositions ?? 2,
    maximumFractionDigits: decimalPositions ?? 2,
  }).format(value)}`
}

export const sentenceCaseToCamelCase = (value) => {
  if (isNullish(value)) return ''

  return (
    `${value}`.charAt(0).toLowerCase() +
    `${value}`.slice(1).replace(/\s+[a-z]/gi, (match) => match.toUpperCase().trim())
  )
}

export const camelCaseToSentenceCase = (value) => {
  if (isNullish(value)) return ''

  return formatSentenceCase(formatUppercaseSeparatedString(`${value}`))
}

export const formatText = (value) => {
  if (isDecimal(value)) return formatNumberWithDecimals(value, 2)
  if (isValidDate(value)) return dateFormat(value)

  return value
}

export const isDecimal = (value) => {
  return !isNaN(parseFloat(value)) && isFinite(value) && value.indexOf('.') != -1
}

export const stripHtml = (html) => {
  var tmp = document.implementation.createHTMLDocument().body
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}
