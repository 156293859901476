import { useRouter } from 'vue-router'
import { RouteNames, RouteItems } from '@/router/routes'
import { useRequest } from '@/services/api/useRequest'
import { Urls } from '@/services/api/Urls'
import { useNotificationsStore } from '@/stores/notifications/NotificationsStore'

export const useNotifications = () => {
  const router = useRouter()

  const navigateToClaim = async (notification) => {
    const { claimId, taskId } = notification.parameters
    const store = useNotificationsStore()
    store.markAsRead([notification.id])

    if (claimId) {
      if (notification.source === 'NonBureau') {
        await navigateToNonBureau(claimId, taskId)
        return
      }
      if (notification.source === 'Bureau') {
        await navigateToBureau(claimId, taskId)
      }
    }
  }

  const navigateToNonBureau = async (claimId, taskId) => {
    const { get } = useRequest()
    await get(Urls.claims.accessCheck(claimId))

    router.push({
      name: RouteNames.ClaimOverview,
      query: {
        claimId: claimId,
        taskId: taskId,
        nr: true,
      },
      params: {
        title: RouteItems.Notifications.title,
        name: RouteNames.Notifications,
      },
    })
  }

  const navigateToBureau = async (claimId, taskId) => {
    router.push({
      name: RouteNames.BureauClaimOverview,
      query: {
        claimId: claimId,
        taskId: taskId,
        noBorder: true,
        nr: true,
      },
      params: {
        title: RouteItems.Notifications.title,
        name: RouteNames.Notifications,
      },
    })
  }

  return {
    navigateToClaim,
  }
}
