import { defineStore, acceptHMRUpdate } from 'pinia'
import { useI18n } from 'vue-i18n'
import { RouteNames } from '@/router/routes'
import { Stores } from '@/stores/Stores'
import { useClaimReportStore } from '@/stores/reports/audit/AuditClaimReportStore'
import { useAdminReportStore } from '@/stores/reports/audit/AuditAdminReportStore'
import { useAccessReportStore } from '@/stores/reports/audit/AuditAccessReportStore'
import { useEmailReportStore } from '@/stores/reports/audit/AuditEmailReportStore'
import {
  canAccessEmailAuditReports,
  canAccessClaimAuditReports,
  canAccessAdminAuditReports,
  canAccessAccessAuditReports,
} from '@/services/ability'

const getReportHeaders = () => {
  var headers = []
  const { t } = useI18n()
  const claimAuditLabel = t('common.claimAuditReport')
  const adminAuditLabel = t('common.adminAuditReport')
  const accessAuditLabel = t('common.accessAuditReport')
  const emailAuditLabel = t('pages.reports.audit.emailAudit')

  if (canAccessClaimAuditReports()) {
    headers.push({ value: ReportIds.Claim, label: claimAuditLabel })
  }
  if (canAccessAdminAuditReports()) {
    headers.push({ value: ReportIds.Admin, label: adminAuditLabel })
  }
  if (canAccessAccessAuditReports()) {
    headers.push({ value: ReportIds.Access, label: accessAuditLabel })
  }
  if (canAccessEmailAuditReports()) {
    headers.push({ value: ReportIds.Email, label: emailAuditLabel })
  }

  return headers
}

export const useAuditReportsStore = defineStore(Stores.AuditReportsStore, {
  state: () => ({
    reports: {
      headers: getReportHeaders(),
    },
    selectedReport: ReportIds.Claim,
  }),
  getters: {
    getSelectedReport: (state) => state.selectedReport,
    getReports: (state) => state.reports,
  },
  actions: {
    init(isBackToSearch) {
      if (isBackToSearch) return

      const claimReportStore = useClaimReportStore()
      const adminReportStore = useAdminReportStore()
      const accessReportStore = useAccessReportStore()
      const emailReportStore = useEmailReportStore()

      this.selectedReport = null
      claimReportStore.initState()
      adminReportStore.initState()
      accessReportStore.initState()
      emailReportStore.initState()
    },
    setSelectedReport(key) {
      this.selectedReport = key
    },
    clear() {
      const claimReportStore = useClaimReportStore()
      const adminReportStore = useAdminReportStore()
      const accessReportStore = useAccessReportStore()
      const emailReportStore = useEmailReportStore()

      claimReportStore.clear()
      adminReportStore.clear()
      accessReportStore.clear()
      emailReportStore.clear()
    },
  },
})

export const getRouteName = (currReport) => {
  if (currReport === ReportIds.Claim) return RouteNames.ClaimAuditReport
  else if (currReport === ReportIds.Admin) return RouteNames.AdminAuditReport
  else if (currReport === ReportIds.Email) return RouteNames.EmailAuditReport
  else return RouteNames.AccessAuditReport
}

export const ReportIds = {
  Claim: '1',
  Admin: '2',
  Access: '3',
  Email: '4',
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuditReportsStore, import.meta.hot))
}
