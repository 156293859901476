<script setup>
import { useRouter } from 'vue-router'
import { IconButton, Navbar } from '@docosoft/docosoft-ui-components'
import Logo from '@/components/templates/logo/Logo.vue'
import Breadcrumb from '@/components/templates/breadcrumb/Breadcrumb.vue'
import NotificationsBell from '@/components/components/notifications/NotificationsBell.vue'
import { RouteNames, RouteItems } from '@/router/routes'
import { useConfigurationStore } from '@/stores/configuration/ConfigurationStore'
import { useApplicationStore } from '@/stores/ApplicationStore'

const applicationStore = useApplicationStore()
const configurationStore = useConfigurationStore()
const router = useRouter()
</script>
<template>
  <Navbar>
    <template #title>
      <Logo :linkTo="RouteNames.NonBureauTasks" />
    </template>

    <template #links> <Breadcrumb /></template>

    <template #toolbar>
      <div class="navbar-top-menu">
        <ul class="icon-menu">
          <li>
            <IconButton
              v-if="applicationStore.isDarkMode"
              icon="sun"
              color="var(--orange)"
              height="2rem"
              :tooltipText="$t('common.lightMode')"
              @onClick="applicationStore.toogleMode"
              tooltipPosition="bottom"
            />
            <IconButton
              v-if="!applicationStore.isDarkMode"
              icon="moon"
              :color="applicationStore.isDarkMode ? 'var(--orange)' : 'var(--purple)'"
              height="2rem"
              :tooltipText="$t('common.darkMode')"
              @onClick="applicationStore.toogleMode"
              tooltipPosition="bottom"
            />
          </li>
          <li>
            <IconButton
              height="2rem"
              :color="applicationStore.isDarkMode ? 'var(--orange)' : 'var(--purple)'"
              :icon="RouteItems.Dashboard.icon"
              @onClick="() => router.push({ name: RouteItems.Dashboard.name })"
              :name="RouteItems.Dashboard.name"
              :title="RouteItems.Dashboard.title"
              :is-active="configurationStore.selectedMenu === RouteItems.Dashboard.name"
              :tooltipText="$t('common.dashboard')"
              tooltipPosition="bottom"
            />
          </li>
          <li>
            <IconButton
              height="2rem"
              :color="applicationStore.isDarkMode ? 'var(--orange)' : 'var(--purple)'"
              :icon="RouteItems.PendingManagerApprovalTasks.icon"
              @onClick="
                () => router.push({ name: RouteItems.PendingManagerApprovalTasks.name })
              "
              :name="RouteItems.PendingManagerApprovalTasks.name"
              :title="RouteItems.PendingManagerApprovalTasks.title"
              :is-active="
                configurationStore.selectedMenu ===
                RouteItems.PendingManagerApprovalTasks.name
              "
              :tooltipText="$t('common.pendingMATasks')"
              tooltipPosition="bottom"
            />
          </li>
          <li>
            <NotificationsBell
              :username="applicationStore.username"
              :isDarkMode="applicationStore.isDarkMode"
              :isDisabled="applicationStore.isNotificationsUnavailable"
              :data="applicationStore.notificationsCount"
            />
          </li>
        </ul>
      </div>
      <User
        v-show="applicationStore.username"
        :username="applicationStore.username ?? ''"
        :email="applicationStore.getCurrentUserEmail ?? ''"
        :logout-label="$t('common.logout')"
        @logout="applicationStore.logout"
        @view-profile-click="() => router.push({ name: RouteItems.ViewProfile.name })"
      />
    </template>
  </Navbar>
</template>
<style lang="scss">
.top-icon-box-wrapper__icon {
  color: white;
}
.top-alert-item__icon-wrapper {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff5357;
  cursor: pointer;
  margin-left: 12px;
}
</style>
